import React from 'react';
import { Typography } from '@mui/material';

const Header1 = ({ text }) => {
  return (
    <Typography
      variant="h1" // You can change the variant if needed
      sx={{
        fontSize: '3rem',
        marginTop: '6rem',
        fontWeight: 800,
        lineHeight: 1.2,
        letterSpacing: '-2%',
        textAlign: 'left',
        color: '#33373d', // Optional: Set a color for the header
      }}
    >
      {text}
    </Typography>
  );
};

export default Header1;
