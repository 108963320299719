import React from 'react';
import { Box } from '@mui/material';

const Liveness = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '32%',
        transform: 'scale(2)',
        backgroundImage: 'url(/images/mockup.png)',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        transition: 'transform 0.5s',
        cursor: 'pointer',
        overflow: 'hidden',
        '&:hover': {
            transform: 'scale(4) rotate(10deg)',
        },
      }}
    >
      <video
        src="/videos/liveness.mp4"
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) scale(0.5)',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 1,
        }}
      />
    </Box>
  );
};

export default Liveness;
