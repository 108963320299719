import React from 'react';
import { Box, Typography, Button, Grid2 } from '@mui/material';
import Button1 from '../buttons/Button1';
import Button2 from '../buttons/Button2';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Title = ({ text }) => (


  <Typography
    variant="h2"
    sx={{
      fontWeight: 'bold',
      fontSize: { xs: '2rem', md: '3rem' },
      color: '#33373d',
    }}
  >
    {text}
  </Typography>
);

const ImageIllustration = ({ src }) => (
  <Box
    component="img"
    src={src}
    alt="Illustration"
    sx={{
      width: { xs: '100%', md: '400px' }, // Fixed width for the image
      maxHeight: { xs: '300px', md: '500px' },
      objectFit: 'cover',
    }}
  />
);

const Hero = () => {

    const handleClick = () => {
        alert('Button clicked!');
    };
const title = (
    <div style={{ opacity: 0.8, transform: 'none', fontWeight: 800 }} className="css-0">
        <span style={{ background: '-webkit-linear-gradient(#8F12FD, #4294E3)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
            Empowering Algeria
        </span>
        <span className="css-0"><br /></span>
        With AI Solutions
    </div>
);
const text = (
    <div
        style={{ opacity: 1, transform: 'none' }}
        className="text1"
    >
        We provide advanced <span className="text2">AI Solutions</span> that enhance efficiency and fuel innovation.
        <span className="css-0"><br /></span>
        From natural language processing to intelligent automation, our technologies empower you to optimize operations, improve customer experiences, and make smarter decisions.
        <span className="css-0"><br /></span>
        Transform your business with the power of AI.
    </div>
);


  const image = '/images/hero.png';

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: { xs: '2rem', md: '4rem' },
        backgroundColor: '#f8f8f8',
      }}
    >
      <Grid2
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Left side: Text content */}
        <Grid2
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: { xs: 'center', md: 'flex-start' },
            textAlign: { xs: 'center', md: 'left' },
            gap: '1.5rem',
            maxWidth: '700px', // Fixed width for the text content
          }}
        >
          {/* Title */}
          <Title text={title} />

          {/* Text */}
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '1rem', md: '1.2rem' },
              color: '#666',
            }}
          >
            {text}
          </Typography>
          <br/>

          {/* Buttons */}
          <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
          <Button1 label="Get Started" onClick={handleClick} />
          <Button2 label="Home" icon={<ArrowForwardIcon />} onClick={handleClick} />

          </Box>
        </Grid2>

        {/* Right side: Image */}
        <Grid2
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
            alignItems: 'center',
          }}
        >
          <img src={image} alt="Hero" 
          className="hero-image"
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Hero;
