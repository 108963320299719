import React from 'react';
import { Container } from '@mui/material';
import Grid1 from '../../components/grids/Grid1';
import Header1 from '../../components/headers/Header1';
import Card from '../../components/cards/Card'; // Import the Card <component></component>
import Card2 from '../../components/cards/Card2'; // Import the Card2 <component></component>
import Translation from '../../components/products/features/Translation'; // Import the Translation component
import Tags from '../../components/products/features/Tags'; // Import the Tags component

const NLP = () => {
return (
    <Container sx={{ mx: 'auto', padding: '2rem', maxWidth: '100%' }}>
        <Header1 text="Natural Language Processing" />
        <br />
        <br />
        <br />

        <Grid1>
            {/* First item spanning 2 columns */}
            <Card
                title="Translation"
                description="With the app DarjApp, you gain access to an advanced translation service specifically tailored to the Algerian dialect, known as Darja. This feature allows users to seamlessly translate Darja into any global language and vice versa, opening up new opportunities for communication and understanding in various contexts."
                content={<Translation />} // Use the Translation component as the content
                sx={{
                    gridColumn: { xs: 'span 1', md: 'span 2' }, // Spans 2 columns on medium screens and above
                }}
            />

            <Card
                title="Sentiment Analysis"
                description="The sentiment analysis service offers a deep dive into the emotional undertones present in Algerian dialect (Darja) communications."
                sx={{ gridColumn: { xs: 'span 1', md: 'span 1' } }} // Regular span
            />

            <Card2
                content={<img 
                    style={{ opacity: 0.5, transform: 'none' }}
                    src="/images/dialects.svg" alt="Dialects" />}
                sx={{ gridColumn: { xs: 'span 1', md: 'span 1' } }} // Regular span
            />

            {/* Last item spanning 2 columns */}
            <Card
                title="Regional Classification"
                description="Given Algeria's vast geographical diversity, the Darja and Tamazight spoken in different regions vary significantly. This service can analyze a phrase and accurately identify the specific region it originates from, whether it's the coastal areas, the high plateaus, or the Sahara. By understanding these linguistic variations, we can provide deeper insights into cultural and regional identity across Algeria."
                content={<Tags tags={['Algerois', 'Oranie', 'Est', 'Tlemceni', 'Kabyle', 'Chaoui', 'Mzabi', 'Tergui', 'Chenoui', 'Chleuh', 'Zenati']} />} // Use the Tags component as the content
                sx={{
                    gridColumn: { xs: 'span 1', md: 'span 2' }, // Spans 2 columns on medium screens and above
                }}
            />
        </Grid1>
    </Container>
);
};

export default NLP;
