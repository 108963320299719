import { Box } from "@mui/material";
import { InputBase, IconButton } from "@mui/material";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import Lottie from "lottie-react";
import loadingAnimation from "./loading.json";
import { Map } from "algeria-map-ts";
import { useState, useEffect } from "react";

const Classification = () => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState("");

  const [mockData, setMockData] = useState({
    Adrar: { value: false, name: "Adrar" },
    Alger: { value: false, name: "Alger" },
    Annaba: { value: false, name: "Annaba" },
    "Aïn Defla": { value: false, name: "Aïn Defla" },
    "Aïn Témouchent": { value: false, name: "Aïn Témouchent" },
    Batna: { value: false, name: "Batna" },
    Biskra: { value: false, name: "Biskra" },
    Blida: { value: false, name: "Blida" },
    "Bordj Badji Mokhtar": { value: false, name: "Bordj Badji Mokhtar" },
    "Bordj Bou Arreridj": { value: false, name: "Bordj Bou Arreridj" },
    Bouira: { value: false, name: "Bouira" },
    Boumerdès: { value: false, name: "Boumerdès" },
    Béchar: { value: false, name: "Béchar" },
    Béjaïa: { value: false, name: "Béjaïa" },
    "Béni Abbès": { value: false, name: "Béni Abbès" },
    Chlef: { value: false, name: "Chlef" },
    Constantine: { value: false, name: "Constantine" },
    Djanet: { value: false, name: "Djanet" },
    Djelfa: { value: false, name: "Djelfa" },
    "El Bayadh": { value: false, name: "El Bayadh" },
    "El Meghaier": { value: false, name: "El Meghaier" },
    "El Menia": { value: false, name: "El Menia" },
    "El Oued": { value: false, name: "El Oued" },
    "El Tarf": { value: false, name: "El Tarf" },
    Ghardaïa: { value: false, name: "Ghardaïa" },
    Guelma: { value: false, name: "Guelma" },
    Illizi: { value: false, name: "Illizi" },
    "In Guezzam": { value: false, name: "In Guezzam" },
    "In Salah": { value: false, name: "In Salah" },
    Jijel: { value: false, name: "Jijel" },
    Khenchela: { value: false, name: "Khenchela" },
    Laghouat: { value: false, name: "Laghouat" },
    "M'Sila": { value: false, name: "M'Sila" },
    Mascara: { value: false, name: "Mascara" },
    Mila: { value: false, name: "Mila" },
    Mostaganem: { value: false, name: "Mostaganem" },
    Médéa: { value: false, name: "Médéa" },
    Naâma: { value: false, name: "Naâma" },
    Oran: { value: false, name: "Oran" },
    Ouargla: { value: false, name: "Ouargla" },
    "Ouled Djellal": { value: false, name: "Ouled Djellal" },
    "Oum El Bouaghi": { value: false, name: "Oum El Bouaghi" },
    Relizane: { value: false, name: "Relizane" },
    Saïda: { value: false, name: "Saïda" },
    "Sidi Bel Abbès": { value: false, name: "Sidi Bel Abbès" },
    Skikda: { value: false, name: "Skikda" },
    "Souk Ahras": { value: false, name: "Souk Ahras" },
    Sétif: { value: false, name: "Sétif" },
    Tamanrasset: { value: true, name: "Tamanrasset" },
    Tiaret: { value: false, name: "Tiaret" },
    Timimoun: { value: false, name: "Timimoun" },
    Tindouf: { value: false, name: "Tindouf" },
    Tipaza: { value: false, name: "Tipaza" },
    Tissemsilt: { value: false, name: "Tissemsilt" },
    "Tizi Ouzou": { value: false, name: "Tizi Ouzou" },
    Tlemcen: { value: false, name: "Tlemcen" },
    Touggourt: { value: false, name: "Touggourt" },
    Tébessa: { value: false, name: "Tébessa" },
  
  });


  const fetchContacts = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://api.datalgeria.com/console2/region', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: search }),
      });

      if (!response.ok) throw new Error('Failed to fetch contacts');
      const data = await response.json();
      const s = JSON.parse(data.classification);
      setRegion(s.region);
      updateMockData(s.region);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateMockData = (region) => {
    const updatedData = { ...mockData }; // Use current state
    console.log('Region:', region);
    console.log('Wilayas for region:', wilayas[region]);
    
    Object.keys(updatedData).forEach(wilaya => {
      updatedData[wilaya].value = 
        region === "All" || (wilayas[region] && wilayas[region].includes(wilaya));
    });
    setMockData(updatedData);
  };
  
  const wilayas = {
    "Algiers": ["Alger", "Blida", "Boumerdès", "Tipaza"],
    "Kabylia": ["Tizi Ouzou", "Béjaïa", "Bouira"],
    "Setif": ["Sétif"],
    "Oran": ["Oran", "Sidi Bel Abbès", "Mostaganem"],
    "Constantine": ["Constantine", "Skikda"],
    "Tlemcen": ["Tlemcen", "Aïn Témouchent"],
    "Annaba": ["Annaba", "El Tarf"],
    "Aures": ["Batna", "Khenchela", "Oum El Bouaghi"],
    "Blida": ["Blida"],
    "Bordj Bou Arreridj / Setif": ["Bordj Bou Arreridj", "Sétif"],
    "Djelfa": ["Djelfa", "M'Sila", "Laghouat"],
  };

  const multipleColor = Object.entries(mockData).reduce((acc, [wilaya, value]) => {
    acc[wilaya] = { ...value, color: value.value ? "#800080" : "#fff" };
    return acc;
  }, {});

  return (
    <Box m="20px">
      <p>
        Given Algeria's vast geographical diversity, the Darja and Tamazight spoken in different regions vary significantly. This service can analyze a phrase and accurately identify the specific region it originates from.
      </p>
      <Box display="flex" backgroundColor={"#fff"} borderRadius="3px">
        <InputBase 
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && fetchContacts()}
          sx={{ ml: 2, flex: 1 }} 
          placeholder="Type the phrase..." 
        />
        <IconButton onClick={fetchContacts}>
          <SentimentSatisfiedOutlinedIcon />
        </IconButton>
      </Box>
      <Box marginTop={"20px"} width="30%">
        {loading ? (
          <Lottie animationData={loadingAnimation} loop={true} />
        ) : (
            <Map
            data={multipleColor}
            stroke="#000"
            HoverColor="#F7cc79"
            hoverStroke="#F7cc79"
            getHoverContent={(wilaya) => `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 10px; background-color: #fff; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); color: #000; font-size: 14px; font-weight: 500; text-align: center;">
             ${wilaya.name}
            <div>`}
    
          />
        )}
      </Box>
    </Box>
  );
};

export default Classification;
