import React from 'react';
import { Button, CircularProgress } from '@mui/material';

const Button3 = ({ sx, label, onClick, loading }) => {
  return (
    <Button
      onClick={onClick}
      disabled={loading}
      sx={{
        background: 'linear-gradient(45deg, #8F12FD, #4294E3)',
        color: '#fff',
        fontFamily: 'InterVariable',
        padding: '10px 20px',
        width: '100%',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          background: 'linear-gradient(45deg, #6a0bad, #3e7bb5)',
        },
        ...sx,
      }}
    >
      {loading ? (
        <CircularProgress
          size={24} // Taille de l'icône
          color='#f9f9f9'
          className="spinner-icon" // Classe CSS pour appliquer l'animation
        />
      ) : (
        label
      )}
    </Button>
  );
};

export default Button3;
