import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Container, Card, Typography, Box, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, MoveToInbox as MoveToInboxIcon } from '@mui/icons-material';

const initialTasks = {
  backlog: [
    { id: 'task-1', content: 'Task 1', order: 1 },
    { id: 'task-2', content: 'Task 2', order: 2 },
    { id: 'task-9', content: 'Task 9', order: 3 },
    { id: 'task-10', content: 'Task 10', order: 4 },
    { id: 'task-11', content: 'Task 11', order: 5 },
    { id: 'task-12', content: 'Task 12', order: 6 },
    { id: 'task-13', content: 'Task 13', order: 7 },
    { id: 'task-14', content: 'Task 14', order: 8 },
    { id: 'task-15', content: 'Task 15', order: 9 },
    { id: 'task-16', content: 'Task 16', order: 10 },
    { id: 'task-17', content: 'Task 17', order: 11 },
    { id: 'task-18', content: 'Task 18', order: 12 },
    { id: 'task-19', content: 'Task 19', order: 13 },
    { id: 'task-20', content: 'Task 20', order: 14 },
    { id: 'task-21', content: 'Task 21', order: 15 },
    { id: 'task-22', content: 'Task 22', order: 16 },
    { id: 'task-23', content: 'Task 23', order: 17 },
    { id: 'task-24', content: 'Task 24', order: 18 },
    { id: 'task-25', content: 'Task 25', order: 19 },
    { id: 'task-26', content: 'Task 26', order: 20 },
    { id: 'task-27', content: 'Task 27', order: 21 },
    { id: 'task-28', content: 'Task 28', order: 22 },
  ],
  selected: [
    { id: 'task-3', content: 'Task 3', order: 1 },
    { id: 'task-4', content: 'Task 4', order: 2 },
  ],
  inProgress: [
    { id: 'task-5', content: 'Task 5', order: 1 },
    { id: 'task-6', content: 'Task 6', order: 2 },
  ],
  finished: [
    { id: 'task-7', content: 'Task 7', order: 1 },
    { id: 'task-8', content: 'Task 8', order: 2 },
  ],
};

function App() {
  const [tasks, setTasks] = useState(initialTasks);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Si pas de destination, on ne fait rien
    if (!destination) {
      return;
    }

    const sourceSectionId = source.droppableId;
    const destinationSectionId = destination.droppableId;

    // Si la tâche est déplacée à l'intérieur de la même section
    const updatedTasks = { ...tasks };

    if (sourceSectionId === destinationSectionId) {
      const sectionTasks = Array.from(updatedTasks[sourceSectionId]);
      const [removed] = sectionTasks.splice(source.index, 1); // Retire la tâche de la position source
      sectionTasks.splice(destination.index, 0, removed); // Ajoute la tâche à la nouvelle position

      updatedTasks[sourceSectionId] = sectionTasks.map((task, index) => ({
        ...task,
        order: index + 1, // Met à jour l'ordre en fonction de l'index
      }));
    } else {
      // Si la tâche est déplacée d'une section à une autre
      const sourceSectionTasks = Array.from(updatedTasks[sourceSectionId]);
      const [removed] = sourceSectionTasks.splice(source.index, 1); // Retire la tâche de la section source

      const destinationSectionTasks = Array.from(updatedTasks[destinationSectionId]);
      destinationSectionTasks.splice(destination.index, 0, removed); // Ajoute la tâche à la nouvelle position dans la section de destination

      updatedTasks[sourceSectionId] = sourceSectionTasks.map((task, index) => ({
        ...task,
        order: index + 1, // Met à jour l'ordre
      }));

      updatedTasks[destinationSectionId] = destinationSectionTasks.map((task, index) => ({
        ...task,
        order: index + 1, // Met à jour l'ordre
      }));
    }

    setTasks(updatedTasks);
  };

return (
    <Container maxWidth="100%" sx={{ padding: '20px' }}>
        <DragDropContext onDragEnd={onDragEnd}>
            <Box display="flex" justifyContent="space-between">
                {Object.keys(tasks).map((sectionId) => (
                    <Droppable droppableId={sectionId} key={sectionId}>
                        {(provided) => (
                            <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                sx={{
                                    width: '23%',
                                    backgroundColor: 'rgb(244, 245, 247)',
                                    borderRadius: '8px',
                                    padding: '10px',
                                    margin: '0 10px',
                                }}
                            >
                                <Typography 
                                sx={{
                                    padding: '13px 10px 17px',
                                    color: 'rgb(94, 108, 132)',
                                    fontSize: '1rem',
                                    fontFamily: 'InterVariable',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}

                                gutterBottom>
                                    {sectionId.charAt(0).toUpperCase() + sectionId.slice(1)} {/* Affichez le nombre de tâches dans chaque section */}
                                    ({tasks[sectionId].length})
                                </Typography>
                                {tasks[sectionId].map((task, index) => (
                                    <Draggable key={task.id} draggableId={task.id} index={index}>
                                        {(provided) => (
                                            <Card
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    margin: '8px 0',
                                                    padding: '10px',
                                                  //  boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 2px 0px',
                                                  boxShadow: 0,
                                                    transition: 'none', // Enlève l'animation de transition
                                                    cursor: 'pointer',
                                                    userSelect: 'none',
                                                    borderRadius: '3px',
                                                    '&:hover': {
                                                        backgroundColor: '#f5f5f5',
                                                    },
                                                }}
                                            >
                                                <Typography sx={{ fontFamily: 'InterVariable', flexGrow: 1 }}>
                                                    {task.content} {/* Affichez l'ordre */}
                                                </Typography>
                                                <Box>
                                                    <IconButton aria-label="edit" size="small">
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" size="small">
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton aria-label="move" size="small">
                                                        <MoveToInboxIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Box>
                                            </Card>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                ))}
            </Box>
        </DragDropContext>
    </Container>
);
}

export default App;
