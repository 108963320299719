import React from 'react';
import { Button } from '@mui/material';

const Button1 = ({ label, onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        background: 'linear-gradient(45deg, #8F12FD, #4294E3)',
        color: '#fff',
        fontFamily: 'InterVariable',
        padding: '10px 20px',
        borderRadius: '5px',
        '&:hover': {
          background: 'linear-gradient(45deg, #6a0bad, #3e7bb5)', // Slightly darker gradient on hover
        },
      }}
    >
      {label}
    </Button>
  );
};

export default Button1;
