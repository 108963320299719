import React from 'react';
import { Box, Typography, Link, Grid } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
    id="footer"
      sx={{
        backgroundColor: '#282c34',
        color: '#ffffff',
        padding: '2rem',
        marginTop: 'auto',

      }}
    >
      <Grid 
        
      container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
            Quick Links
          </Typography>
          <Box>
            <Link href="#" color="inherit" sx={{ display: 'block', marginBottom: '0.5rem' }}>
              Home
            </Link>
            <Link href="#" color="inherit" sx={{ display: 'block', marginBottom: '0.5rem' }}>
              About Us
            </Link>
            <Link href="#" color="inherit" sx={{ display: 'block', marginBottom: '0.5rem' }}>
              Services
            </Link>
            <Link href="#" color="inherit" sx={{ display: 'block', marginBottom: '0.5rem' }}>
              Contact
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
            Follow Us
          </Typography>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Link href="#" color="inherit">
              <Facebook />
            </Link>
            <Link href="#" color="inherit">
              <Twitter />
            </Link>
            <Link href="#" color="inherit">
              <Instagram />
            </Link>
            <Link href="#" color="inherit">
              <LinkedIn />
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
            Contact Us
          </Typography>
          <Typography variant="body2">Email: contact@datalgeria.com</Typography>
          <Typography variant="body2">Phone: +213 667 86 88 34</Typography>
        </Grid>
      </Grid>

      <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
        <Typography variant="body2">
          © {new Date().getFullYear()} Datalgeria. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
