import React, { useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Box,
} from '@mui/material';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';

const SentimentAnalysis = () => {
    const [phrases, setPhrases] = useState(Array(5).fill('')); // Initialiser avec 5 phrases vides
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    // Handle input change
    const handlePhraseChange = (index, value) => {
        const newPhrases = [...phrases];
        newPhrases[index] = value;
        setPhrases(newPhrases);
    };

    // Handle submission of phrases
    const handleProcess = async () => {
        setLoading(true); // Démarrer le chargement
        const response = await fetch('https://api.datalgeria.com/console2/sa', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text: phrases }), // Assurez-vous que le format est correct
        });

        const data = await response.json();
        setResults(data.text.t.sentiment); // Mettre à jour les résultats
        setLoading(false); // Arrêter le chargement
    };

    return (
        <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
            <Typography variant="h4" align="center" gutterBottom>
                Sentiment Analysis
            </Typography>
            <Paper elevation={3} style={{ padding: '2rem', borderRadius: '10px', backgroundColor: '#f9f9f9' }}>
                <Grid container spacing={2}>
                    {phrases.map((phrase, index) => (
                        <Grid item xs={12} key={index}>
                            <TextField
                                label={`Phrase ${index + 1}`}
                                variant="outlined"
                                fullWidth
                                value={phrase}
                                onChange={(e) => handlePhraseChange(index, e.target.value)}
                                disabled={results.length > 0} // Disable inputs après traitement
                            />
                        </Grid>
                    ))}
                </Grid>
                <Box display="flex" justifyContent="space-between" marginTop="1rem">
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleProcess} 
                        disabled={loading}
                        style={{ flexGrow: 1, marginRight: '1rem' }}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Process'}
                    </Button>
                </Box>
            </Paper>

            {results.length > 0 && (
                <Paper elevation={3} style={{ marginTop: '2rem', padding: '2rem', borderRadius: '10px', backgroundColor: '#f9f9f9' }}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Results
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Phrase</strong></TableCell>
                                    <TableCell><strong>Sentiment</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {phrases.map((phrase, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{phrase}</TableCell>
                                        <TableCell>
                                            <IconButton>
                                                {results[index] === 'positive' ? (
                                                    <SentimentVerySatisfiedIcon color="success" />
                                                ) : results[index] === 'negative' ? (
                                                    <SentimentDissatisfiedIcon color="error" />
                                                ) : (
                                                    <SentimentNeutralIcon color="action" />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            )}
        </Container>
    );
};

export default SentimentAnalysis;
