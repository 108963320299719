import React from 'react';
import { Box } from '@mui/material';

const Translation = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '9999px', // Full border radius
        borderWidth: '1px',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: '0.25rem', // var(--chakra-space-1)
        paddingBottom: '0.25rem', // var(--chakra-space-1)
        paddingInlineStart: '2rem', // var(--chakra-space-8)
        paddingInlineEnd: '0.5rem', // var(--chakra-space-2)
        background: '#fff',
        position: 'relative',
        width: '350px',
        height: '50px',
      }}
    >
      <video
        src="/videos/translation.mp4"
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) scale(1)',
          width: '90%',
          height: '85%',
          objectFit: 'cover',
          zIndex: 1,
        }}
      />
    </Box>
  );
};

export default Translation;
