import React from 'react';
import { Box } from '@mui/material';

const Grid1 = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridGap: '2rem',
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)', // For extra-small screens
          sm: 'repeat(2, 1fr)', // For small screens
          md: 'repeat(3, 1fr)', // For medium screens and above
        },
        position: 'relative',
      }}
    >
      {children}
    </Box>
  );
};

export default Grid1;
