import axios from 'axios';

const completeAuth = async (token, username, password) => {
    // Prépare les données à envoyer
    const data = {
        token: token,
        username: username,
        password: password,
    };

    // Effectue la requête POST vers l'API et retourne les données
    const response = await axios.post('https://api.datalgeria.com/auth/complete', data);
    return response.data; // Renvoie les données de la réponse
};

export default completeAuth;
