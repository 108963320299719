import React, { useState } from 'react';
import { TextField, Box, IconButton, Avatar, List, ListItem, ListItemAvatar, ListItemText, Container, Button, Select, MenuItem } from '@mui/material';
import { Send, EmojiEmotions, Attachment } from '@mui/icons-material';
import axios from 'axios';
import { motion } from 'framer-motion';
import Grid1 from 'components/grids/Grid1';
import Header1 from 'components/headers/Header1';
import Card from 'components/cards/Card';
import Tags from 'components/products/features/Tags';

const botPhoto = 'https://scontent.fqsf1-2.fna.fbcdn.net/v/t39.30808-6/448927947_122128849460276537_8288691666646047248_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=9O-TJOBVGQAQ7kNvgH4R7QE&_nc_ht=scontent.fqsf1-2.fna&_nc_gid=AugtDGWrOzHiSmVH_fz5nwf&oh=00_AYDQLcnAIFxwgAWZi5wTDAoN36N3uY5346yRX2ZF-4Ps8Q&oe=671BA13A'; // Replace with actual bot image URL
const userPhoto = localStorage.getItem('picture');

// Save messages per model
const initialMessagesState = {
  pttdarja1: [],
  pttkabyle1: []
};

const messageVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
};

const Playground = () => {
    const [model, setModel] = useState('pttdarja1'); // Default model
    const [allMessages, setAllMessages] = useState(initialMessagesState); // Messages for each model
    const [input, setInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);

    const handleSendMessage = async () => {
        if (input.trim() === '') return;

        const userMessage = {
            id: allMessages[model].length,
            text: input,
            sender: 'user',
        };

        // Update messages for the current model
        setAllMessages((prevMessages) => ({
            ...prevMessages,
            [model]: [...prevMessages[model], userMessage]
        }));

        setInput('');
        setIsTyping(true); // Start typing animation for the bot

        try {
            // Send the message to the bot API with the selected model
            const response = await axios.post('https://api.datalgeria.com/console/chat', {
                message: input,
                model: model
            });

            const botMessage = {
                id: allMessages[model].length + 1,
                text: response.data.response,
                sender: 'bot',
            };

            setIsTyping(false); // Stop typing indicator
            setAllMessages((prevMessages) => ({
                ...prevMessages,
                [model]: [...prevMessages[model], botMessage]
            }));
        } catch (error) {
            const errorMessage = {
                id: allMessages[model].length + 1,
                text: "Oops, something went wrong. Please try again later.",
                sender: 'bot',
            };
            setIsTyping(false); // Stop typing indicator
            setAllMessages((prevMessages) => ({
                ...prevMessages,
                [model]: [...prevMessages[model], errorMessage]
            }));
        }
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleModelChange = (event) => {
        setModel(event.target.value);
    };

    return (
        <Container 
            id="playground"
            sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#f9f9f9' }}>
            
            {/* Floating section to select model */}
            <Box sx={{ position: 'fixed', top: '10px', right: '10px', zIndex: 100 }}>
                <Select value={model} onChange={handleModelChange} sx={{ backgroundColor: 'white' }}>
                    <MenuItem value="pttdarja1">Darja LLM</MenuItem>
                    <MenuItem value="pttkabyle1">Kabyle LLM</MenuItem>
                </Select>
            </Box>

            <Box sx={{ overflowY: 'auto', flex: 1, padding: '20px' }}>
                <List>
                    {allMessages[model].length === 0 && (
                        model === 'pttdarja1' ? (
                            <Container sx={{ mx: 'auto', maxHeight: '100%', maxWidth: '100%' }}>
                                <Header1 text="Introducing Algerian Darja LLM" />
                                <br />
                                <Grid1>
                                    <Card
                                        title="Easy communication"
                                        description="Algerian Darja was known for its lack of standardization and its use in informal settings. The Algerian Darja LLM is a language model that can help you communicate with ease in Algerian Darja."
                                        sx={{ gridColumn: { xs: 'span 1', md: 'span 2' } }}
                                    />
                                    <Card
                                        title="Latin & Arabic"
                                        description='You can ask the chatbot in any format: Salam Khouya or سلام خويا'
                                        sx={{ gridColumn: { xs: 'span 1', md: 'span 1' } }}
                                    />
                                    <Card
                                        title="Test it now"
                                        description="Start a conversation with the chatbot and see how it can help you communicate in Algerian Darja."
                                        content={<Tags 
                                            
                                            tags={['Kifah nenja7 fel machrou3 ta3i ?', 'Wech na9der ndir f annaba ?', 'شحال من ولاية كاين فالدزاير ؟', 'A3tili ism bahi nsemmi bih wlidi', 'Wech na9der ndir b 15 fel bac ?', '3arafni brou7ek']} />}
                                        sx={{ gridColumn: { xs: 'span 1', md: 'span 3' } }}
                                    />
                                </Grid1>
                            </Container>
                        ) : (
                            <Container sx={{ mx: 'auto', maxHeight: '100%', maxWidth: '100%' }}>
                                <Header1 text="Introducing Kabyle LLM" />
                                <br />
                                <Grid1>
                                    <Card
                                        title="Easy communication in Kabyle"
                                        description="Kabyle is a language of the Berber family spoken in Algeria. The Kabyle LLM model can assist you in communicating in Kabyle."
                                        sx={{ gridColumn: { xs: 'span 1', md: 'span 2' } }}
                                    />
                                    <Card
                                        title="Kabyle Writing"
                                        description='You can ask the chatbot in Latin or Tifinagh scripts.'
                                        sx={{ gridColumn: { xs: 'span 1', md: 'span 1' } }}
                                    />
                                    <Card
                                        title="Test the Kabyle LLM"
                                        description="Try communicating with the bot in Kabyle and see how it can help."
                                        content={<Tags tags={['Azul fellak, dachu izemregh adh-xadhmegh a Vgayeth ?', 'Anwa i-dh Kateb Yacine ?']} />}
                                        sx={{ gridColumn: { xs: 'span 1', md: 'span 3' } }}
                                    />
                                </Grid1>
                            </Container>
                        )
                    )}

                    {allMessages[model].map((message, index) => (
                        <motion.div key={index} variants={messageVariants} initial="hidden" animate="visible" transition={{ duration: 0.3 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar src={message.sender === 'bot' ? botPhoto : userPhoto} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={message.sender === 'bot' ? "datalgeria" : "You"}
                                    secondary={<span style={{ whiteSpace: 'pre-wrap' }}>{message.text}</span>}
                                    primaryTypographyProps={{ fontWeight: 'bold' }}
                                    secondaryTypographyProps={{ fontSize: '16px' }}
                                />
                            </ListItem>
                        </motion.div>
                    ))}

                    {isTyping && (
                        <motion.div variants={messageVariants} initial="hidden" animate="visible" transition={{ duration: 0.3 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar src={botPhoto} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Bot"
                                    secondary={<img src="https://cssbud.com/wp-content/uploads/2021/08/threedots.gif" alt="typing..." style={{ width: '50px' }} />}
                                    primaryTypographyProps={{ fontWeight: 'bold' }}
                                    secondaryTypographyProps={{ fontSize: '16px' }}
                                />
                            </ListItem>
                        </motion.div>
                    )}
                </List>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px', marginBottom: '1em', backgroundColor: '#fff' }}>
                <IconButton>
                    <EmojiEmotions />
                </IconButton>
                <IconButton>
                    <Attachment />
                </IconButton>
                <TextField
                    fullWidth
                    placeholder="Type a message..."
                    variant="outlined"
                    value={input}
                    onChange={handleInputChange}
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                    inputProps={{ autoComplete: 'off' }} // Disable text suggestions
                    sx={{
                        margin: '0 10px',
                        '& label.Mui-focused': { color: 'white' },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: 'white' },
                            '&:hover fieldset': { borderColor: 'white' },
                            '&.Mui-focused fieldset': { borderColor: 'white' },
                        },
                    }}
                />
                <IconButton color="primary" onClick={handleSendMessage}>
                    <Send />
                </IconButton>
            </Box>
        </Container>
    );
};

export default Playground;
