import React from 'react';

const Header = (props) => {
    return (
        <header 

        {...props}

        style={styles.header}>
        </header>
    );
};

const styles = {
    header: {
        position: "absolute",
        right: '-30%',
        width: '100%',
        left: 'unset',
        aspectRatio: '1 / 1',
        height: 'unset',
        bottom: '-60%',
        background: 'radial-gradient(circle, #424bff 0%, #2493ee 24%, #37eaf7 44%, #c624ee 57%, transparent 70%)',
        filter: 'blur(100px)',
        opacity: '10%',
        padding: '20px',
        textAlign: 'center',
    },
    title: {
        color: 'white',
        fontSize: '24px',
    },
};

export default Header;