import React, { useState, useRef,useCallback, useEffect } from "react";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./editorTools";
import axios from "axios";
import {
  Select,
  MenuItem,
  Button,
  TextField,
  Box,
  Typography,
  Chip,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";


const AddProject = () => {
	const editorCore = useRef(null);
  const ReactEditorJS = createReactEditorJS();


  const handleInitialize = useCallback((instance) => {
    // await instance._editorJS.isReady;
    instance._editorJS.isReady
        .then(() => {
            // set reference to editor
            editorCore.current = instance;
        })
        .catch((err) => console.log("An error occured", err));
}, []);


  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [universities, setUniversities] = useState([]);
  const [selectedUniversities, setSelectedUniversities] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [projectManager, setProjectManager] = useState(""); // État pour le project manager
  const [projectType, setProjectType] = useState(""); // État pour le type de projet
  const [universitySearch, setUniversitySearch] = useState("");
  const [userSearch, setUserSearch] = useState("");
  const [loadingUniversities, setLoadingUniversities] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [filteredUniversities, setFilteredUniversities] = useState([]);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const authToken = localStorage.getItem('authToken');

  const handleSave1 = async () => {
    const projectData = {
      name,
      description:  JSON.stringify(description),
      universities: selectedUniversities,
      users: selectedUsers,
      project_manager: projectManager,
      project_type: projectType,
    };

    try {
    await axios.post("https://api.datalgeria.com/president/addProject", projectData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
    }
    });
      // Reset form after successful submission
      setName("");
      setSelectedUniversities([]);
      setSelectedUsers([]);
      setProjectManager("");
      setProjectType("");
      setOpenDescriptionModal(false);
    } catch (error) {
      console.error("Error while adding project:", error);
    }
    };


    
    const handleSave = useCallback(async () => {
		// retrieve data inserted
		const savedData = await editorCore.current.save();
		// save data
		setDescription(savedData)
        setOpenDescriptionModal(false);
    })


  const fetchUniversities = async () => {
    setLoadingUniversities(true);
    try {
      const response = await axios.get("https://api.datalgeria.com/club/selectUniversities");
      setUniversities(response.data);
      setFilteredUniversities(response.data);
    } catch (error) {
      console.error("Error while fetching universities:", error);
    } finally {
      setLoadingUniversities(false);
    }
  };

  const fetchUsers = async (universityIds) => {
    const universityParam = universityIds.join(',');

    try {
      const response = await axios.get("https://api.datalgeria.com/club/selectMembers", {
        params: { universities: universityParam },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs:", error);
    }
  };

  const handleUniversityChange = (selected) => {
    if (selected.includes("all")) {
      setSelectedUniversities(universities.map((uni) => uni.id));
    } else {
      setSelectedUniversities(selected);
    }

    if (selected.length > 0) {
      fetchUsers(selected);
    } else {
      setUsers([]);
    }
  };

  const handleUserChange = (selected) => {
    setSelectedUsers(selected);
  };

  const handleRemoveUniversity = (universityId) => {
    setSelectedUniversities(selectedUniversities.filter((id) => id !== universityId));
  };

  const handleRemoveUser = (userId) => {
    setSelectedUsers(selectedUsers.filter((id) => id !== userId));
  };

  useEffect(() => {
    fetchUniversities();
  }, []);

  useEffect(() => {
    if (universitySearch) {
      const filtered = universities.filter(uni => uni.name.toLowerCase().includes(universitySearch.toLowerCase()));
      setFilteredUniversities(filtered);
    } else {
      setFilteredUniversities(universities);
    }
  }, [universitySearch, universities]);

  useEffect(() => {
    if (selectedUniversities.length > 0) {
      fetchUsers(selectedUniversities);
    } else {
      setUsers([]);
    }
  }, [selectedUniversities]);

  useEffect(() => {
    if (userSearch) {
      const filteredUsers = users.filter(user => user.name.toLowerCase().includes(userSearch.toLowerCase()));
      setUsers(filteredUsers);
    } else {
      fetchUsers(selectedUniversities);
    }
  }, [userSearch, selectedUniversities]);

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Ajouter un Nouveau Projet
      </Typography>
      <TextField
        label="Nom du Projet"
        variant="outlined"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6">Type de Projet</Typography>
        <Select
          value={projectType}
          onChange={(e) => setProjectType(e.target.value)}
          fullWidth
          displayEmpty
          sx={{ marginBottom: 2 }}
        >
          <MenuItem value="" disabled>Choisissez un type de projet</MenuItem>
          <MenuItem value="total">Total</MenuItem>
          <MenuItem value="partial">Partiel</MenuItem>
          <MenuItem value="personal">Personnalisé</MenuItem>
        </Select>
      </Box>
     
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6">Universités</Typography>
        <TextField
          label="Rechercher des universités"
          variant="outlined"
          fullWidth
          value={universitySearch}
          onChange={(e) => setUniversitySearch(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        {loadingUniversities && <Typography>Loading universities...</Typography>}
        <FormControlLabel
          control={<Checkbox 
            checked={selectedUniversities.length === universities.length} 
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedUniversities(universities.map(uni => uni.id));
              } else {
                setSelectedUniversities([]);
              }
            }} 
          />}
          label="Tout sélectionner"
        />
        <Select
          multiple
          value={selectedUniversities}
          onChange={(e) => handleUniversityChange(e.target.value)}
          fullWidth
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((id) => {
                const university = universities.find((uni) => uni.id === id);
                return university ? (
                  <Chip key={id} label={university.name} onDelete={() => handleRemoveUniversity(id)} />
                ) : null;
              })}
            </Box>
          )}
          sx={{ marginBottom: 2 }}
        >
          {filteredUniversities.map((university) => (
            <MenuItem key={university.id} value={university.id}>
              {university.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6">Utilisateurs</Typography>
        <TextField
          label="Rechercher des utilisateurs"
          variant="outlined"
          fullWidth
          value={userSearch}
          onChange={(e) => setUserSearch(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        {loadingUsers && <Typography>Loading users...</Typography>}
        <FormControlLabel
          control={<Checkbox 
            checked={selectedUsers.length === users.length} 
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedUsers(users.map(user => user.user_id));
              } else {
                setSelectedUsers([]);
              }
            }} 
          />}
          label="Tout sélectionner"
        />
        <Select
          multiple
          value={selectedUsers}
          onChange={(e) => handleUserChange(e.target.value)}
          fullWidth
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((id) => {
                const user = users.find((usr) => usr.user_id === id);
                return user ? (
                  <Chip key={id} label={user.name} onDelete={() => handleRemoveUser(id)} />
                ) : null;
              })}
            </Box>
          )}
          sx={{ marginBottom: 2 }}
        >
          {users.map((user) => (
            <MenuItem key={user.user_id} value={user.user_id}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6">Project Manager</Typography>
        <Select
          value={projectManager}
          onChange={(e) => setProjectManager(e.target.value)}
          fullWidth
          displayEmpty
          sx={{ marginBottom: 2 }}
        >
          <MenuItem value="" disabled>Choisissez un gestionnaire de projet</MenuItem>
          {users.map((user) => (
            <MenuItem key={user.user_id} value={user.user_id}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
        <Button variant="outlined" onClick={() => setOpenDescriptionModal(true)}>
          Ajouter une Description
        </Button>
        <Button variant="contained" onClick={handleSave1}>
          Enregistrer
        </Button>
      </Box>

      {/* Modal pour l'éditeur de description */}
      <Dialog open={openDescriptionModal} onClose={() => setOpenDescriptionModal(false)} fullWidth>
        <DialogTitle>Ajouter une Description</DialogTitle>
        <DialogContent>
          <ReactEditorJS 
          defaultValue={description}
          				onInitialize={handleInitialize}

          ref={editorCore} tools={EDITOR_JS_TOOLS} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDescriptionModal(false)}>Annuler</Button>
          <Button onClick={handleSave}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddProject;
