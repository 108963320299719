import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import CustomSideBar from './Sidebar';
import { styled } from '@mui/system';
import Header from 'components/global/Header';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu'; // Importing a menu icon for the button
import { Drawer } from '@mui/material'; // Importing Drawer for mobile view

// Style for the main content area (responsive)
const MainContent = styled('div')(({ theme, isMobile }) => ({
  minHeight: '100vh', // Ensure full-height content
  width: isMobile ? '100%' : 'calc(100% - 250px)', // Full width on mobile, calc on desktop
  backgroundColor: '#f9f9f9', // Light gray background
  marginLeft: isMobile ? 0 : 0, // Adjust margin for mobile
}));

const ConsoleLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screen size
  const [sidebarOpen, setSidebarOpen] = useState(false); // State to control sidebar visibility

  // Function to toggle sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div style={{ display: 'flex' }}>
      {/* Button to open the sidebar on mobile */}
      {isMobile && (
        <IconButton
          onClick={toggleSidebar}
          style={{ position: 'absolute', zIndex: 1000, margin: '10px' }} // Positioning the button
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Sidebar always visible on larger screens, toggleable on mobile */}
      <div style={{ display: isMobile && !sidebarOpen ? 'none' : 'block' }}>
        <CustomSideBar />
      </div>

      {/* Main content */}
      <MainContent isMobile={isMobile}>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <Header />
        </div>
        <Outlet />
      </MainContent>

      {/* Drawer for mobile view (hidden when sidebar is visible) */}
      <Drawer
        anchor="left"
        open={sidebarOpen}
        onClose={toggleSidebar} // Close on backdrop click
      >
        <CustomSideBar />
      </Drawer>
    </div>
  );
};

export default ConsoleLayout;
