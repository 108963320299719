import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import './Chatbot.css'; // Assuming you will create a CSS file for styling
import Speech from "speak-tts";



const Chatbot = ({ locale = 'ar-SA' }) => {
    const { transcript, resetTranscript } = useSpeechRecognition();
    const [isListening, setIsListening] = useState(false);
    const [response, setResponse] = useState('');
    const [audioSrc, setAudioSrc] = useState('');
    const [showResult, setShowResult] = useState('');
    const speech = new Speech();
    speech
      .init({
        lang: "ar-SA",
       // voice: "Microsoft Charline Online (Natural) - French (Belgium)",
        // speed
        rate: 1.3,
      })
      .then((data) => {
        // The "data" object contains the list of available voices and the voice synthesis params
        console.log("Speech is ready, voices are available", data);
      })
      .catch((e) => {
        console.error("An error occurred while initializing: ", e);
      });

      const handleClick = () => {
        speech
          .speak({
            text: showResult,
          })
          .then(() => {
            console.log("Success !");
          })
          .catch((e) => {
            console.error("An error occurred :", e);
          });
      };
    // Determine font based on locale
    useEffect(() => {
        const body = document.body;

        if (typeof locale === 'string' && locale.includes('ar')) {
            body.style.fontFamily = "'El Messiri', sans-serif";
            setShowResult('ابدأ الكلام...'); // Arabic prompt
        } else {
            body.style.fontFamily = "'Raleway', sans-serif";
            setShowResult('Say something...'); // English prompt
        }
    }, [locale]);

    const startListening = () => {
        setIsListening(true);
        SpeechRecognition.startListening({ continuous: true, language: locale });
        resetTranscript();
    };

    const stopListening = () => {
        setIsListening(false);
        SpeechRecognition.stopListening();
       // handleClick()
        handleResponse();
    };

    const handleResponse = () => {
        // Constructing the URL with the query parameter
        const url = `https://bot.hostilna.com/speak?p=${encodeURIComponent(transcript)}`;
    
        fetch(url, {
            method: 'GET', // Use GET instead of POST
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) { // Checking for success flag
                    setResponse(data.reply); // Assuming 'reply' contains the answer
                    playAudio(data.reply); // Play the audio from the response
                } else {
                    console.error('Error from API:', data.reply); // Log any error messages from the API
                }
            })
            .catch(error => console.error('Error:', error));
    };
    

    const playAudio = async (src) => {
        try {
            await speech.speak({
                text: src,
            });
            console.log("Success!");
        } catch (e) {
            console.error("An error occurred:", e);
        }
    };

    useEffect(() => {
        if (isListening) {
            setShowResult(transcript || showResult);
        }
    }, [transcript, isListening, showResult]);

    return (
        <div id="background" className={isListening ? 'listening' : ''}>
            <p id="result">{showResult}</p>
            <button onClick={isListening ? stopListening : startListening}>
                {isListening ? 'Stop Listening' : 'Start Listening'}
            </button>
            <p>{response}</p>
        </div>
    );
};

// PropTypes for type checking
Chatbot.propTypes = {
    locale: PropTypes.string,
};

export default Chatbot;
