import React from 'react';
import Header from 'components/global/Header';
import Hero from '../components/global/Hero';
import NLP from '../components/products/NLP';
import Security from '../components/products/Security';
import Footer from '../components/global/Footer';
import { Box } from '@mui/material';

const Home = () => {
  return (
    <Box>
      <Header />
      <Hero />
      <NLP />
      <Security />
      <Footer />

    </Box>
  );
};

export default Home;
