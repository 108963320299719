import React from 'react';
import { TextField, Box, Typography, InputAdornment } from '@mui/material';

const Input1 = ({ label, onClick, description, error, icon, ...otherProps }) => {
    return (
        <Box sx={{ marginTop: '1rem' }}>
            {/* Input field */}
            <TextField
                label={label}
                variant="outlined"
                fullWidth
                onClick={onClick}
                error={!!error} // Show error state if error prop is passed
                helperText={error} // Display error message below input
                sx={{
                    marginTop: '1rem',
                    fontFamily: 'InterVariable',
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#ccc', // Default border color
                        },
                        '&:hover fieldset': {
                            borderColor: '#333', // Border color on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#333', // Border color on focus
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: '#666', // Default label color
                        '&.Mui-focused': {
                            color: '#333', // Label color on focus
                        },
                    },
                }}
                InputProps={{
                    startAdornment: icon ? (
                        <InputAdornment position="start">
                            {icon} {/* Render the icon if passed */}
                        </InputAdornment>
                    ) : null,
                }}
                {...otherProps} // Spread other props to TextField
            />

            {/* Description text */}
            {description && (
                <Typography variant="body2" sx={{ color: '#666', marginTop: '0.5rem' }}>
                    {description}
                </Typography>
            )}
        </Box>
    );
};

export default Input1;
