import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import GitHubIcon from '@mui/icons-material/GitHub';
import MailOutline from '@mui/icons-material/MailOutline';
import Button3 from '../components/buttons/Button3';
import Input1 from '../components/inputs/Input1';
import login from 'api/login'
import { useNavigate } from 'react-router-dom';

const Signin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [identifier, setIdentifier] = useState(''); // State to hold the email input value
  const [password, setPassword] = useState(''); // État pour le mot de passe

  const [error, setError] = useState(null); // State to hold errors if any

  const redirectToAuth = async (social) => {
    window.location.href = `https://api.datalgeria.com/auth/${social}`;
  };
  const handleSendOtp = async () => {
    setLoading(true);
    setError(null); // Réinitialise l'erreur avant d'envoyer l'OTP
    try {
      const data = await login({ identifier, password }); // Appel à l'API pour envoyer l'OTP
  
      console.log(data);
  
      if (data.success) {
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('email', data.email);
        localStorage.setItem('picture', data.picture);
        localStorage.setItem('username', data.username);
        window.location.href = '/';
      } else {
        setError('Failed to send OTP. Please try again.');
      }
    } catch (err) {
      console.log(err);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  
  return (
    <Box id="signup" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, minHeight: '100vh', position: 'relative' }}>
      {/* Left Section */}
      <Box
        sx={{
          width: { xs: '100%', md: '50%' },
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
        }}
      >
        <Box
  sx={{
    textAlign: 'left',
    maxWidth: '600px',
  }}
>
<img src="/logo.svg" alt="Datalgeria Logo" style={{ width: '150px', marginBottom: '2rem', alignSelf: 'flex-start' }} />

  {[
  { title: 'Access to AI Models', description: 'Seamlessly access pre-trained AI models for NLP, computer vision, and more.' },
  { title: 'Demo Console', description: 'Test and explore our AI models in real-time through an intuitive demo console.' },
  { title: 'Test API Key', description: 'Easily generate and test API keys for AI integration in your applications.' },
  { title: 'Custom AI Solutions', description: 'Tailor-made AI solutions crafted to meet your unique business needs.' },
  ].map((feature, index) => (
    <Box
      key={index}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '2rem', // Adds space between each feature
      }}
    >
      {/* Circle with check mark */}
      <Box
        sx={{
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          backgroundColor: '#e5daf8', // Green background for the check circle
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '1rem', // Adds space between the check circle and the text
        }}
      >
  <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              focusable="false"
              className="chakra-icon css-s48i3l"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
      </Box>

      {/* Feature title and description */}
      <Box>
        <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: '1rem', marginBottom: '0.8rem' }}>
          {feature.title}
        </Typography>
        <Typography variant="body2" sx={{ color: '#7d7f83' }}>
          {feature.description}
        </Typography>
      </Box>
    </Box>
  ))}
</Box>

      </Box>

      {/* Right Section */}
      <Box
        sx={{
          width: { xs: '100%', md: '50%' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
          position: 'relative',
          overflow: 'hidden', // To ensure the background stays within the container
        }}
      >
        {/* Background div */}
        <Box
          sx={{
            backgroundImage: 'radial-gradient(at top left, #4f1d9e 30%, transparent 80%), radial-gradient(at bottom, #2ab4d9 0%, transparent 60%), radial-gradient(at bottom left, #fff 0%, transparent 50%)',
            backgroundBlendMode: 'saturation',
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: -1,
            opacity: 0.2,
            height: '100%',
            width: '100%',
            borderLeftWidth: '1px',
            borderColor: 'gray.200',
          }}
        />

        <Typography variant="h5" sx={{ marginBottom: '2rem', textAlign: 'center', fontFamily: 'InterVariable', fontWeight: 'bold', fontSize: '1.5rem' }}>
          Connect with your account
        </Typography>
        <Box sx={{ width: '100%', maxWidth: '300px' }}>
        <Button
            variant="contained"
            fullWidth
            startIcon={<GoogleIcon />}
            onClick={() => redirectToAuth('google')}
            sx={{ marginBottom: '1rem', backgroundColor: 'transparent', color: '#333', border: '1px solid #333', boxShadow: 'none', fontFamily: 'InterVariable' }}
          >
            Continue with Google
          </Button>
          <Button 
          onClick={() => redirectToAuth('github')}
          variant="contained" fullWidth startIcon={<GitHubIcon />} sx={{ marginBottom: '1rem', backgroundColor: '#333', boxShadow: 'none', fontFamily: 'InterVariable' }}>
            Continue with GitHub
          </Button>
          <Button
          onClick={() => redirectToAuth('discord')}
           variant="contained" fullWidth  sx={{ backgroundColor: '#333', boxShadow: 'none', fontFamily: 'InterVariable'}}>
            Continue with Discord
          </Button>
          <Input1
        label="Email / Username"
        description="Please enter a valid email address or a username"
        error={null}
        icon={<MailOutline />} // Icon to display on the left side
        onClick={() => console.log('Input clicked!')}
        value={identifier}
        onChange={(e) => setIdentifier(e.target.value)} // Update email state
      />
      <Input1
        label="Password"
        description="Please enter your password"
        error={null}
        icon={<MailOutline />} // Icon to display on the left side
        onClick={() => console.log('Input clicked!')}
        value={password}
        onChange={(e) => setPassword(e.target.value)} // Update password state
      />

<Button3
            label="Sign In"
            onClick={handleSendOtp} // Trigger the OTP sending
            loading={loading} // Pass loading state to display spinner
            sx={{ marginTop: '2rem' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Signin;
