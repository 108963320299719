import React, { useState, useEffect } from "react"

import { useParams, Link } from "react-router-dom"
// lottie



const Auth = () => {
  // get authToken, username, picture from url
    const { authToken, username, email, picture } = useParams()
    console.log(authToken)

    // when user is here : save to session + redirect to /

    useEffect(() => {
        localStorage.setItem("authToken", authToken)
        localStorage.setItem("username", username)
        localStorage.setItem("email", email)
        // if decodeURIComponent is not null or "null"
        if (picture !== "null" || picture !== null) {
        localStorage.setItem("picture", decodeURIComponent(picture))
        }
      window.location.href = "/"
    }, [authToken, username, picture])
    


  return (
    <>
      <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        fontSize: "20px",
      }}
      
      >

            <img  
            src="/logo.svg"
            alt="logo"
            style={{ width: "600px", height: "100px" }}
            />
        </div>
    </>
  )
}

export default Auth