import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Header1 from 'components/headers/Header1';
import FaceDetectionAnimation from './FaceDetectionAnimation';

const Club2 = () => {
    const faces = [
        { x: 65, y: 30, width: 120, height: 120, name: 'Dr. Abdelouahab ATTIA', p1: "Departement Chief of Computer Science - BBA", p2: "Computer Vision expert", dblp: "https://dblp.org/pid/36/1458", scholar: "https://scholar.google.com/citations?user=PGT1G-cAAAAJ&hl=en" },
        { x: 430, y: 50,  width: 120, height: 120, name: 'Pr. Farid NOUIOUA', p1: "Director of AI House - BBA", p2: "NLP and ML expert", dblp: "https://dblp.org/pid/202/5602", scholar : "https://scholar.google.fr/citations?user=OQ8rlg4AAAAJ&hl=fr" }
      ];
    return (
        <Container sx={{ mx: 'auto', padding: '2rem', maxWidth: '100%' }}>
            <Header1 text="Our teachers" />
            <br /><br /><br />
            <Box
                sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'flex-start', gap: 2 }}
            >
                <Box sx={{ flex: 1 }}>
                    <Typography
                        sx={{
                            color: '#7d7f83',
                            fontSize: '1rem',
                            fontFamily: 'InterVariable',
                            fontWeight: 400
                        }}
                    >
                      At <b>datalgeria</b>, we are privileged to have the unwavering support and guidance of some of the brightest minds in academia and industry.
                      <br/>
                      <br/>

                       Our club is enriched by a remarkable team of expert teachers and intellectuals,
                        each specializing in cutting-edge fields such as Artificial Intelligence,
                         Natural Language Processing (NLP), Machine Learning, and Data Science. These individuals are not just educators but pioneers in their respective disciplines, continuously pushing the boundaries of technology and innovation. With years of experience in research and practical applications, they offer invaluable insights that drive our projects forward. Their mentorship provides us with a deep understanding of complex algorithms, language models, and the latest trends in AI, enabling us to tackle real-world challenges with confidence. Through their dedication, they foster an environment of learning and creativity, inspiring our members to explore new ideas and reach their full potential.
                         <br/>
                         <br/> 
                          At every step of our journey, these experts stand by us, ensuring that Datalgeria Club remains at the forefront of technological advancements, ready to make a profound impact in the fields of AI and NLP.
                    </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <FaceDetectionAnimation imageSrc="/images/teachers.jpg" faces={faces} />
                </Box>
            </Box>
        </Container>
    );
};

export default Club2;