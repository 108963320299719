import React, { useState, useRef, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Box, Button, Checkbox, FormControlLabel, TextField, RadioGroup, Radio, FormLabel, CircularProgress, 
    Select, MenuItem, InputLabel, LinearProgress, Typography, FormControl } from "@mui/material";
import "./style.css";
import communes from '../../helpers/communes.json'; // Import the JSON data
import { AccountCircle, AlternateEmail, Phone } from '@mui/icons-material';
import Input1 from '../../components/inputs/Input1';
import TextArea1 from '../../components/inputs/TextArea1';
import TagSelector from '../../components/global/TagSelector';
import Universities from "../../components/global/Universities";
import joinClub from 'api/club/join';

const Join = () => {
  
    const [filteredCommunes, setFilteredCommunes] = useState([]); // Communes for the selected Wilaya
    const [authToken, setAuthToken] = useState('A7wCBkaE49PMx99Snl6jWQqE6gdBoQ8c');
    const [loading, setLoading] = useState(false)
      // Extract unique Wilayas from the JSON data
      const wilayas = communes.reduce((acc, item) => {
        if (!acc.some(w => w.wilaya_code === item.wilaya_code)) {
          acc.push({ wilaya_name: item.wilaya_name_ascii, wilaya_code: item.wilaya_code });
        }
        return acc;
      }, []);


  const [formData, setFormData] = useState({
    checkboxes: [false, false, false],
    selectedWilaya: "",
    selectedCommune: "",
    selectedUniversity: null,
    name: "",
    fname: "",
    email: "",
    phone: "",
    skills: [],
    technologies: [],
    q1: "",
    q2: "",
    q3: "",
    isWorking: false,
    actualWork: "",
    website: "",
    hasCompany: false,
    userType: "student",
  });


  const handleSkills = (selectedTags) => {
    console.log("Tags sélectionnés:", selectedTags);
    setFormData({ ...formData, skills: selectedTags });

};

const handleTechnologies = (selectedTags) => {
    console.log("Tags sélectionnés:", selectedTags);
    setFormData({ ...formData, technologies: selectedTags });
    console.log(formData);
}

  const skills = [
    "Web Development", "Mobile Development", "Artificial Intelligence",
    "Web Scraping", "NLP", "Computer Vision", "Data Science", "Machine Learning",
    "Deep Learning", "Cybersecurity", "Blockchain", "Cloud Computing", "DevOps",
    "UI/UX Design", "Project Management", "Big Data", "Software Engineering",
    "Quantum Computing", "Game Development", "Embedded Systems", "IoT",
    "Augmented Reality", "Virtual Reality", "Robotics", "Database Management"
  ];

  const technologies = [
    "Python", "JavaScript", "Java", "C++", "C#", "PHP", "Swift", "Kotlin", "Ruby", "Go", "Rust", "TypeScript", "Flutter", "React", "React Native", "Tenserflow", "PyTorch", "Numpy", "Pandas", "Matplotlib", "Seaborn", "Scikit-learn", "NLTK", "Spacy", "OpenCV", "Django", "Flask", "Spring", "Node.js", "Express", "Angular", "Vue.js", "Svelte", "Laravel", "Symfony", "Rails", "ASP.NET", "Unity", "Unreal Engine", "MongoDB", "MySQL", "PostgreSQL", "SQLite", "Firebase", "AWS", "Azure", "Google Cloud", "Docker", "Kubernetes", "Git"];

    const handleSubmit = async (e) => {

        setLoading(true);
      
        // Copy formData and modify selectedUniversity to selectedUniversity.name
        const formData2 = {
          ...formData,
          selectedUniversity: formData.selectedUniversity?.name || null, // Use selectedUniversity.name, or null if it's not set
        };
      
        console.log(formData2);
      
        try {
          // Make the API request to join the club
          const response = await joinClub(authToken, formData2);
      
          // Check the response for success
          if (response.success) {
            // Redirect to the home page on success
            window.location.href = "/";
          } else {
            // Log the response in case of an error
            console.error(response);
          }
        } catch (error) {
          // Log any errors that occur during the API request
          console.error('Error joining the club:', error);
        } finally {
          setLoading(false); // Ensure loading state is reset
        }
      };
      


  // Filter communes based on the selected Wilaya
  useEffect(() => {
    if (formData.selectedWilaya) {
      const communesForWilaya = communes.filter(
        (commune) => commune.wilaya_code == formData.selectedWilaya
      );
      console.log(communesForWilaya);
      setFilteredCommunes(communesForWilaya);
      // selectedcommune
      setFormData({ ...formData, selectedCommune: "" });
    }
  }, [formData.selectedWilaya]);

  const [currentStep, setCurrentStep] = useState(0);
  const refs = useRef({});

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...formData.checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setFormData({ ...formData, checkboxes: updatedCheckboxes });
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e) => {
    setFormData({ ...formData, selectValue: e.target.value });
  };


  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
const nextStep = () => {
    if (validateStep()) {
        if (currentStep === steps.length - 1) {
            console.log(formData);
            // handlesubmit
            handleSubmit();
        } else {
            setCurrentStep(currentStep + 1);
        }
    }
};

const validateStep = () => {
    if (currentStep === 0) {
        return formData.checkboxes.filter(Boolean).length === 3;
    }
    if (currentStep === 1) {
        return formData.selectedWilaya && formData.selectedCommune;
    }
    if (currentStep === 2) {
        return formData.selectedUniversity;
    }
    if (currentStep === 3) {
        return formData.name && formData.fname && formData.email && formData.phone;
    }

    return true;
};

  useEffect(() => {
    if (refs.current[currentStep]) {
      refs.current[currentStep].focus();
    }
  }, [currentStep]);






const steps = [
    {
        title: "Step 1",
        content: (
            <div className="form">
                <Box display="flex" flexDirection="column">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.checkboxes[0]}
                                onChange={() => handleCheckboxChange(0)}
                                sx={{ color: "primary.main", '&.Mui-checked': { color: "secondary.main" } }}
                            />
                        }
                        label="I agree with the terms and conditions of the club of datalgeria"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.checkboxes[1]}
                                onChange={() => handleCheckboxChange(1)}
                                sx={{ color: "primary.main", '&.Mui-checked': { color: "secondary.main" } }}
                            />
                        }
                        label="I agree with the terms of services and the privacy policy of the plateform of datalgeria"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.checkboxes[2]}
                                onChange={() => handleCheckboxChange(2)}
                                sx={{ color: "primary.main", '&.Mui-checked': { color: "secondary.main" } }}
                            />
                        }
                        label="I'm actually a student / teacher in a : university / private school, or a partner of datalgeria"
                    />
                    <br/><br/>
                    <Button variant="contained" onClick={nextStep} disabled={formData.checkboxes.filter(Boolean).length < 3}>
                        Next
                    </Button>
                </Box>
            </div>
        ),
    },
    {
        title: "Step 2",
        content: (
            <div className="form">
            <Box display="flex" flexDirection="column">
                <FormControl fullWidth>
                    <InputLabel 

                    id="select-label">Choose a wilaya</InputLabel>
                    <Select
                                        sx = {{
                                            minWidth: '500px',
                                        }}
                        labelId="select-label"
                        id="select"
                        value={formData.selectedWilaya}
                        onChange={(e) => setFormData({ ...formData, selectedWilaya: e.target.value })}
                        fullWidth
                    >
                        {wilayas.map((wilaya, index) => (
                            <MenuItem key={index} value={wilaya.wilaya_code}>
                                {wilaya.wilaya_name}
                            </MenuItem>
                        ))}
                    </Select>

                    

                </FormControl>
                <br/>
                {formData.selectedWilaya && (
                    <FormControl fullWidth>
                        <InputLabel id="select-label">Choose a commune</InputLabel>
                        <Select
                            labelId="select-label"
                            sx = {{
                                minWidth: '500px',
                            }}
                            id="select"
                            value={formData.selectedCommune}
                            onChange={(e) => setFormData({ ...formData, selectedCommune: e.target.value })}
                            fullWidth
                        >
                            {filteredCommunes.map((commune, index) => (
                                <MenuItem key={index} value={commune.id}>
                                    {commune.commune_name_ascii}
                                </MenuItem>
                            ))}
                        </Select>

                    </FormControl>
                )}
<br/><br/><br/>
                <Button variant="contained" onClick={nextStep} disabled={!formData.selectedWilaya || !formData.selectedCommune}>
                    Next
                </Button><br/>
                <Button variant="outlined" onClick={previousStep}>
                    Back
                </Button>
            </Box>
            </div>
        ),
    },
    {
        title: "Step 3",
        content: (
            <Box 
            sx={{
                width: '500px',
            }}
            display="flex" flexDirection="column">
                <Universities
                value= {formData.selectedUniversity}
                onSelectionChange={(selectedUniversity) => setFormData({ ...formData, selectedUniversity: selectedUniversity })}
                />
                <br/><br/>
                <Button variant="contained" onClick={nextStep} disabled={!formData.selectedUniversity}>
                    Next
                </Button>
                <br/>
                <Button variant="outlined" onClick={previousStep}>
                    Back
                </Button>
            </Box>
        ),
    },
    {
        title: "Step 4",
        content: (
            <Box 
            sx={{
                width: '500px',
            }}
            display="flex" flexDirection="column">
                <Input1
                    label="First Name(s)"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    inputRef={(el) => (refs.current[2] = el)}
                    fullWidth
                    margin="normal"
                    icon= {<AccountCircle/>}
                />
                <Input1
                    label="Family Name"
                    name="fname"
                    value={formData.fname}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    icon= {<AccountCircle/>}
                />
                <Input1
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    icon= {<AlternateEmail/>}
                />
                <Input1
                    label="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    icon= {<Phone/>}
                />
                <br/> <br/>
                <Button variant="contained" onClick={nextStep} disabled={!formData.name || !formData.fname || !formData.email || !formData.phone}>
                    Next
                </Button> <br/>
                <Button variant="outlined" onClick={previousStep}>
                    Back
                </Button>
            </Box>
        ),
    },
    {
        title: "Step 5",
        content: (
            <Box 
            sx={{
                width: '500px',
            }}
            display="flex" flexDirection="column">
             <TagSelector
        tags={skills}
        value={formData.skills}
        limit={10} // Limite à 10 tags sélectionnables
        onSelectionChange={handleSkills}
      />
                <br/> <br/>
                <Button variant="contained" onClick={nextStep} disabled={!formData.name || !formData.fname || !formData.email || !formData.phone}>
                    Next
                </Button> <br/>
                <Button variant="outlined" onClick={previousStep}>
                    Back
                </Button>
            </Box>
        ),
    },
    {
        title: "Step 6",
        content: (
            <Box 
            sx={{
                width: '500px',
            }}
            display="flex" flexDirection="column">
             <TagSelector
        tags={technologies}
        value={formData.technologies}
        limit={100} // Limite à 10 tags sélectionnables
        onSelectionChange={handleTechnologies}
      />
                <br/> <br/>
                <Button variant="contained" onClick={nextStep} disabled={!formData.name || !formData.fname || !formData.email || !formData.phone}>
                    Next
                </Button> <br/>
                <Button variant="outlined" onClick={previousStep}>
                    Back
                </Button>
            </Box>
        ),
    },
    {
        title: "Step 7",
        content: (
            <div className="form">
                <Box 
                            sx={{
                                width: '500px',
                            }}
                            display="flex" flexDirection="column">
                    <TextArea1
                        label="Why do you want to join the club of datalgeria ?"
                        name="q1"
                        value={formData.q1}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextArea1
                        label="What are your expectations from the club of datalgeria ?"
                        name="q2"
                        value={formData.q2}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextArea1
                        label="How did you hear about the club of datalgeria ?"
                        name="q3"
                        value={formData.q3}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <br/><br/>
                    <Button variant="contained" onClick={nextStep} disabled={!formData.q1 || !formData.q2 || !formData.q3}>
                        Next
                    </Button>
                    <br/>
                    <Button variant="outlined" onClick={previousStep}>
                        Back
                    </Button>
                </Box>
            </div>
        ),

    },
    {
        title: "Step 8",
        content: (
            <Box 
            sx={{
                width: '500px',
            }}
            display="flex" flexDirection="column">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.isWorking}
                            onChange={() => setFormData({ ...formData, isWorking: !formData.isWorking })}
                            sx={{ color: "primary.main", '&.Mui-checked': { color: "secondary.main" } }}
                        />
                    }
                    label="Are you actually working?"
                />
                {formData.isWorking && (
                    <TextArea1
                        label="Actual Work"
                        name="actualWork"
                        value={formData.actualWork}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.ownCompany}
                            onChange={() => setFormData({ ...formData, ownCompany: !formData.ownCompany })}
                            sx={{ color: "primary.main", '&.Mui-checked': { color: "secondary.main" } }}
                        />
                    }
                    label="Do you own a company?"
                /><br/>
        <Typography
        sx= {{
            textAlign: "left"
        }}
        >I'm a:</Typography>
        <RadioGroup
  aria-label="user-type"
  name="user-type"
  value={formData.userType} // Utiliser 'userType' dans formData pour stocker la valeur sélectionnée
  onChange={(e) => setFormData({ ...formData, userType: e.target.value })} // Mettre à jour 'userType'
  row // Affiche les boutons radio sur une seule ligne
>
  <FormControlLabel value="student" control={<Radio />} label="Student" />
  <FormControlLabel value="teacher" control={<Radio />} label="Teacher" />
  <FormControlLabel value="other" control={<Radio />} label="Other" />
</RadioGroup>
                <Input1
                    label="Website / Portfolio (optional)"
                    name="website"
                    value={formData.website}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                <br/><br/>
                <Button 
                
                variant="contained" onClick={nextStep}
                
                disabled={loading}

                sx={{
                  background: 'linear-gradient(45deg, #8F12FD, #4294E3)',
                  color: '#fff',
                  fontFamily: 'InterVariable',
                  padding: '10px 20px',
                  width: '100%',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #6a0bad, #3e7bb5)',
                  },
                }}
              >
                {loading ? (
                  <CircularProgress
                    size={24} // Taille de l'icône
                    color='#f9f9f9'
                    className="spinner-icon" // Classe CSS pour appliquer l'animation
                  />
                ) : (
                  <span>
                    Submit
                  </span>
                )}
              </Button>

                <br/>
                <Button variant="outlined" onClick={previousStep}>
                    Back
                </Button>
            </Box>
        ),
    }
];
const progress = ((currentStep + 1) / steps.length) * 100;

  return (
    <Box className="big-container">
                   {/* Progress Bar */}
                   <Box sx={{ position: 'absolute', bottom: '0', left: '0', right: '0', zIndex: '999', padding: '1rem', backgroundColor: 'white' }}>
                <Typography 
                sx={{ color: '#333', fontFamily: 'InterVariable' }}
                variant="h6">{`${currentStep + 1} / ${steps.length}`}</Typography>
                <LinearProgress variant="determinate" value={progress} sx={{ height: '10px', borderRadius: '5px' }} />
            </Box>

      <TransitionGroup component={null}>
        <CSSTransition key={currentStep} timeout={500} classNames="fade">
          <div className="store-container">{steps[currentStep].content}</div>
        </CSSTransition>
      </TransitionGroup>
    </Box>
  );
};

export default Join;
