import React, { useState, useEffect } from "react";
import { Chip, Box } from "@mui/material";

const TagSelector = ({ tags, limit, value = [], onSelectionChange }) => {
  const [selectedTags, setSelectedTags] = useState(value);

  useEffect(() => {
    // Met à jour les tags sélectionnés à partir de la valeur donnée via les props
    setSelectedTags(value);
  }, [value]);

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      const updatedTags = selectedTags.filter((selected) => selected !== tag);
      setSelectedTags(updatedTags);
      onSelectionChange(updatedTags); // Retourne la liste mise à jour des tags sélectionnés
    } else {
      if (selectedTags.length < limit) {
        const updatedTags = [...selectedTags, tag];
        setSelectedTags(updatedTags);
        onSelectionChange(updatedTags); // Retourne la liste mise à jour des tags sélectionnés
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
      {tags.map((tag) => (
        <Chip
          key={tag}
          label={tag}
          clickable
          onClick={() => handleTagClick(tag)}
          color={selectedTags.includes(tag) ? "primary" : "default"}
        />
      ))}
    </Box>
  );
};

export default TagSelector;