import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Circle, Line, Image as KonvaImage } from 'react-konva';
import { useSpring, animated } from 'react-spring';
import useImage from 'use-image';

// Composant séparé pour l'animation des boîtes d'information
const AnimatedInfoBox = ({ face, isVisible, lineEndPoint }) => {
    const boxAnimation = useSpring({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(-20px)', // Animation fluide
        config: { tension: 200, friction: 20 },
        delay: 1000, // Ajout d'un délai d'1 seconde
    });

    return (
        <animated.div
            style={{
                ...boxAnimation,
                position: 'absolute',
                top: lineEndPoint.y - 40, // Ajustement pour placer la boîte au-dessus de la ligne
                left: lineEndPoint.x - 100, // Centrer la boîte par rapport à la ligne
                width: '200px', // Largeur fixe pour les boîtes
                background: 'rgba(255, 255, 255, 0.9)',
                padding: '10px',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
        >
            <h4>{face.name}</h4>
            <p>{face.p1}</p>
            <p>{face.p2}</p>
        </animated.div>
    );
};

const FaceDetectionAnimation = ({ imageSrc, faces = [] }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [lineVisible, setLineVisible] = useState(false); // État pour contrôler la visibilité de la ligne
    const [image] = useImage(imageSrc);
    const imageRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
                if (entry.isIntersecting) {
                    // Démarrer le délai d'affichage de la ligne
                    setTimeout(() => {
                        setLineVisible(true); // Afficher la ligne après 1 seconde
                    }, 1000);
                } else {
                    setLineVisible(false); // Cacher la ligne si l'image n'est plus visible
                }
            },
            { threshold: 0.1 } // Ajuster le seuil si nécessaire
        );

        if (imageRef.current) {
            observer.observe(imageRef.current);
        }

        return () => {
            if (imageRef.current) {
                observer.unobserve(imageRef.current);
            }
        };
    }, []);

    return (
        <div style={{ position: 'relative' }} ref={imageRef}>
            {/* Konva Stage pour dessiner sur le canvas */}
            <Stage width={600} height={650}>
                <Layer>
                    {/* Image de fond avec ratio d'origine */}
                    {image && (
                        <KonvaImage
                            image={image}
                            width={600}
                            height={(image.height / image.width) * 600} // Ajuster la hauteur pour conserver le ratio
                            offsetX={300} // Centrer l'image
                            offsetY={200} // Centrer l'image
                            x={300} // Position x pour centrer
                            y={200} // Position y pour centrer
                            draggable // Si tu veux déplacer l'image
                        />
                    )}

                    {/* Vérification de faces pour éviter l'erreur */}
                    {Array.isArray(faces) && faces.map((face, index) => (
                        <React.Fragment key={index}>
                            {/* Cercle autour du visage */}
                            <Circle
                                x={face.x + face.width / 2}
                                y={face.y + face.height / 2}
                                radius={face.width / 2}
                                stroke="#f9f9f9"
                                opacity={isVisible ? 1 : 0.3}
                                delay={1000}
                                
                                strokeWidth={2}
                                dash={[10, 5]}
                            />

                            {/* Ligne qui apparait avec un délai, si visible */}
                            {isVisible && lineVisible && (
                                <Line
                                    points={[ // Point de départ et d'arrivée
                                        face.x + face.width / 2, // Point de départ (milieu du cercle)
                                        face.y + face.height, // Point de départ (bas du cercle)
                                        face.x + face.width / 2, // Point d'arrivée (verticalement en bas)
                                        face.y + face.height + 100, // 100px en dessous
                                    ]}
                                    stroke="#f9f9f9"
                                    strokeWidth={2}
                                    dash={[10, 5]}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </Layer>
            </Stage>

            {/* Composant AnimatedInfoBox pour chaque visage */}
            {Array.isArray(faces) && faces.map((face, index) => (
                <AnimatedInfoBox
                    key={index}
                    face={face}
                    isVisible={isVisible}
                    lineEndPoint={{
                        x: face.x + face.width / 2,
                        y: face.y + face.height + 100,
                    }}
                />
            ))}
        </div>
    );
};

export default FaceDetectionAnimation;
