import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Map } from 'algeria-map-ts';

import Button2 from '../buttons/Button2';

import { useNavigate } from 'react-router-dom';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const Hero2 = () => {
  const navigate = useNavigate();
  const [mockData, setMockData] = useState({
    Adrar: { value: false, name: "Adrar" },
    Alger: { value: true, name: "Alger" },
    Annaba: { value: false, name: "Annaba" },
    "Aïn Defla": { value: false, name: "Aïn Defla" },
    "Aïn Témouchent": { value: false, name: "Aïn Témouchent" },
    Batna: { value: false, name: "Batna" },
    Biskra: { value: false, name: "Biskra" },
    Blida: { value: false, name: "Blida" },
    "Bordj Badji Mokhtar": { value: false, name: "Bordj Badji Mokhtar" },
    "Bordj Bou Arreridj": { value: true, name: "Bordj Bou Arreridj" },
    Bouira: { value: false, name: "Bouira" },
    Boumerdès: { value: false, name: "Boumerdès" },
    Béchar: { value: false, name: "Béchar" },
    Béjaïa: { value: false, name: "Béjaïa" },
    "Béni Abbès": { value: false, name: "Béni Abbès" },
    Chlef: { value: false, name: "Chlef" },
    Constantine: { value: false, name: "Constantine" },
    Djanet: { value: false, name: "Djanet" },
    Djelfa: { value: false, name: "Djelfa" },
    "El Bayadh": { value: false, name: "El Bayadh" },
    "El Meghaier": { value: false, name: "El Meghaier" },
    "El Menia": { value: false, name: "El Menia" },
    "El Oued": { value: false, name: "El Oued" },
    "El Tarf": { value: false, name: "El Tarf" },
    Ghardaïa: { value: false, name: "Ghardaïa" },
    Guelma: { value: false, name: "Guelma" },
    Illizi: { value: false, name: "Illizi" },
    "In Guezzam": { value: false, name: "In Guezzam" },
    "In Salah": { value: false, name: "In Salah" },
    Jijel: { value: false, name: "Jijel" },
    Khenchela: { value: false, name: "Khenchela" },
    Laghouat: { value: false, name: "Laghouat" },
    "M'Sila": { value: true, name: "M'Sila" },
    Mascara: { value: false, name: "Mascara" },
    Mila: { value: false, name: "Mila" },
    Mostaganem: { value: false, name: "Mostaganem" },
    Médéa: { value: false, name: "Médéa" },
    Naâma: { value: false, name: "Naâma" },
    Oran: { value: false, name: "Oran" },
    Ouargla: { value: false, name: "Ouargla" },
    "Ouled Djellal": { value: false, name: "Ouled Djellal" },
    "Oum El Bouaghi": { value: false, name: "Oum El Bouaghi" },
    Relizane: { value: false, name: "Relizane" },
    Saïda: { value: false, name: "Saïda" },
    "Sidi Bel Abbès": { value: false, name: "Sidi Bel Abbès" },
    Skikda: { value: false, name: "Skikda" },
    "Souk Ahras": { value: false, name: "Souk Ahras" },
    Sétif: { value: false, name: "Sétif" },
    Tamanrasset: { value: false, name: "Tamanrasset" },
    Tiaret: { value: false, name: "Tiaret" },
    Timimoun: { value: false, name: "Timimoun" },
    Tindouf: { value: false, name: "Tindouf" },
    Tipaza: { value: false, name: "Tipaza" },
    Tissemsilt: { value: false, name: "Tissemsilt" },
    "Tizi Ouzou": { value: false, name: "Tizi Ouzou" },
    Tlemcen: { value: false, name: "Tlemcen" },
    Touggourt: { value: false, name: "Touggourt" },
    Tébessa: { value: false, name: "Tébessa" },
  });

  const assignColor = (value) => {
    return value ? "#800080" : "#fff"; // Color based on value
  };

  const multipleColor = Object.entries(mockData).reduce((acc, [wilaya, value]) => {
    acc[wilaya] = { ...value, color: assignColor(value.value) };
    return acc;
  }, {});

return (
    <Box
        sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, // Change layout based on screen size
            height: '100vh', // Full viewport height
        }}
    >
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: { xs: 2, sm: 0 }, // Padding adjustment
            }}
        >
            <LazyLoadImage
                alt="Example Image"
                src="https://images.pexels.com/photos/5553052/pexels-photo-5553052.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                effect="opacity"
                objectFit="cover"
                style={{ 
                    maxWidth: '100vw', 
                    maxHeight: '100vh', 
                    objectFit: 'cover' 
                }}
            />
        </Box>
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                background: 'linear-gradient(to bottom,  #e0e0e0, #fff, #e0e0e0)', // Linear gradient background
            }}
        >
            <Map
                data={multipleColor}
                stroke="#000"
                HoverColor="#F7cc79"
                hoverStroke="#F7cc79"
                getHoverContent={(wilaya) => `<div style="padding: 10px; background: #fff; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); text-align: center;">
                    ${wilaya.name}
                </div>`}
            />
              <Box
                sx={{
                    width: '100%',
                    backgroundColor: '#ccc',
                    marginTop: '1rem',
                    padding: '1rem 0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                      <Button2 
                      label="Join the club" 
                        onClick={() => navigate("/club/join")}
                      icon={<ArrowForwardIcon />} />


            </Box>

        </Box>
    </Box>
);
};

export default Hero2;
