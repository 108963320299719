import React from 'react';
import { Box, Typography } from '@mui/material';

const Card = ({ title, description, content, sx }) => {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        padding: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Ensure space between title/description and content
        ...sx, // Allow additional styles via sx prop
      }}
    >
      <Box sx={{ textAlign: 'left' }}> {/* Align title and description to the left */}
        <Typography 
        // intervariable + bold
        color="#33373d"
        sx={{ fontWeight: 800, fontSize: '1.5rem', fontFamily: 'InterVariable !important', marginBottom: '1rem' }}
       >{title}</Typography>
        <Typography 
        
         sx={{fontSize: '1rem', marginBottom: '1rem', color: '#7d7f83', fontFamily: 'InterVariable !important'


         }}>
          {description}
        </Typography>
      </Box>
      <Box sx={{ marginTop: 'auto' }}> {/* Ensures content stays at the bottom */}
        {content}
      </Box>
    </Box>
  );
};

export default Card;
