import axios from 'axios';

const joinClub = async (authToken, data) => {
  try {
    const response = await axios.post('https://api.datalgeria.com/club/join', data, {
      headers: {
        'Authorization': `Bearer ${authToken}`, // Send the token in the Authorization header
        'Content-Type': 'application/json',
      },
    });

    // Return the response data in JSON format
    return response.data;
  } catch (error) {
    // Always return the error in JSON format
    if (error.response) {
      // Server responded with a status other than 2xx
      return { success: false, message: error.response.data.message || 'Request failed' };
    } else if (error.request) {
      // No response was received from the server
      return { success: false, message: 'No response from server' };
    } else {
      // Some other error occurred during the request
      return { success: false, message: 'Error in sending request' };
    }
  }
};

export default joinClub;
