import React from 'react';
import Header from '../../components/global/Header';
import Hero2 from '../../components/global/Hero2';
import Club1 from '../../components/club/Club1';
import Club2 from '../../components/club/Club2';

import Security from '../../components/products/Security';
import Footer from '../../components/global/Footer';
import { Container, Box } from '@mui/material';

const Home = () => {






  return (
    <Box>
      <Header />
      <Hero2 />
      <Club1 />
      <Club2 />

      <Footer />
    </Box>
  );
};

export default Home;
