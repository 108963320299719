import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import Navbar from './components/global/Navbar';
import Navbar2 from './components/global/Navbar2';
import Home from './routes/Home';
import Signup from './routes/Signup';
import Login from './routes/Login';
import Complete from './routes/Complete';
import Auth from './routes/Auth';
import Confirm from './routes/Confirm';

import Club from './routes/Club/Club';
import Join from './routes/Club/Join';
import Dashboard from './routes/Club/Dashboard';
import CalendarPage from './routes/Club/Calendar';
import CustomSideBar from './routes/Club/Sidebar';
import ClubValidations from './routes/Club/Validations';
import ClubUniversities from './routes/Club/Universities';

// President Routes
import ClubPresidentProjects from './routes/Club/President/Projects';
import NewProject from './routes/Club/President/NewProject';
import ProjectSections from './routes/Club/President/ProjectSections';
import SectionSteps from './routes/Club/President/SectionSteps';

import ConsoleLayout from './routes/Console/ConsoleLayout';
import ConsoleMain from './routes/Console/Main';
import ConsoleTranslation from './routes/Console/Translation';
import ConsoleTTS from './routes/Console/TTS';
import ConsoleSentimentAnalysis from './routes/Console/Sentiment-analysis';
import ConsoleClassification from './routes/Console/Classification';

// Dummy authentication function (replace with real auth logic)
const isAuthenticated = () => true;

// PrivateRoute component to protect routes
const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};

// Custom hook to manage body overflow based on route
const useBodyOverflow = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/club') || location.pathname.startsWith('/console')) {
      document.body.style.overflow = 'hidden'; // Disable body scroll when in club routes
    } else {
      document.body.style.overflow = ''; // Re-enable body scroll for other routes
    }

    return () => {
      document.body.style.overflow = ''; // Clean up on unmount
    };
  }, [location]);
};

// Layout for Club pages with Sidebar (private layout)
const ClubLayout = () => {
  useBodyOverflow();

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <CustomSideBar /> {/* Persistent Sidebar */}
      <main style={{ flexGrow: 1, padding: '20px', overflowY: 'auto' }}>
        <Outlet /> {/* Nested Routes */}
      </main>
    </div>
  );
};

// Layout for Public Pages (with Navbar)
const PublicLayout = () => {
  const authToken = localStorage.getItem('authToken');
  return (
    <>
      {authToken ? <Navbar2 /> : <Navbar />} {/* Use Navbar2 if authToken exists */}
      <Outlet /> {/* Nested Routes */}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route element={<PublicLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/complete/:token" element={<Complete />} />
          <Route path="/confirm/:token" element={<Confirm />} />
          <Route path="/auth/:authToken/:username/:email/:picture" element={<Auth />} />
          <Route path="/club/join" element={<Join />} />
          <Route path="/club" element={<Club />} />

        </Route>

        {/* Club Routes */}
        <Route path="/club/*" element={<PrivateRoute><ClubLayout /></PrivateRoute>}>
          {/* General Club Pages */}
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="calendar" element={<CalendarPage />} />
          <Route path="validations" element={<ClubValidations />} />
          <Route path="universities" element={<ClubUniversities />} />

          {/* Club President Pages */}
          <Route path="president/projects" element={<ClubPresidentProjects />} />
          <Route path="president/new-project" element={<NewProject />} />
          <Route path="president/project/:projectId/sections" element={<ProjectSections />} />
          <Route path="president/section/:sectionId/steps" element={<SectionSteps />} />
        </Route>

        {/* Console Routes */}
        <Route path="/console/*" element={<PrivateRoute><ConsoleLayout /></PrivateRoute>}>
        <Route path="chat" element={<ConsoleMain />} />
          <Route path="dashboard" element={<ConsoleMain />} />
          <Route path="translation" element={<ConsoleTranslation />} />
          <Route path="tts" element={<ConsoleTTS />} />
          <Route path="sentiment-analysis" element={<ConsoleSentimentAnalysis />} />
          <Route path="regional-classification" element={<ConsoleClassification />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
