import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, TextField, Button, Box, Paper, Modal } from '@mui/material';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const formatDate = (date) => {
  return date ? date.toLocaleDateString() : '';
};

const CalendarPage = () => {
  const calendarRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: '',
    startDate: '',
    endDate: '',
    description: '',
    color: '#2196f3',
  });
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const calendar = new Calendar(calendarRef.current, {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      events: events,
      editable: true,
      selectable: true,
      eventClick: (info) => {
        setSelectedEvent(info.event);
        setOpen(true);
      },
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,dayGridDay',
      },
      eventDidMount: (info) => {
        info.el.style.backgroundColor = info.event.color;
      },
    });

    calendar.render();

    return () => {
      calendar.destroy(); // Cleanup on component unmount
    };
  }, [events]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent((prev) => ({ ...prev, [name]: value }));
  };

  const addEvent = () => {
    if (!newEvent.title || !newEvent.startDate || !newEvent.endDate) {
      alert('Please fill all fields');
      return;
    }

    const event = {
      title: newEvent.title,
      start: newEvent.startDate,
      end: newEvent.endDate,
      description: newEvent.description,
      color: newEvent.color,
    };

    setEvents((prev) => [...prev, event]);
    setNewEvent({ title: '', startDate: '', endDate: '', description: '', color: '#2196f3' });
  };

  const handleModalClose = () => {
    setOpen(false);
    setSelectedEvent(null);
  };

  return (
    <Container maxWidth="md" sx={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Calendar
      </Typography>

      <div ref={calendarRef} />

      <Paper sx={{ marginTop: '20px', padding: '20px' }}>
        <Typography variant="h6">Add Event</Typography>
        <Box display="flex" flexDirection="column">
          <TextField
            label="Event Title"
            name="title"
            value={newEvent.title}
            onChange={handleInputChange}
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            type="date"
            label="Start Date"
            name="startDate"
            value={newEvent.startDate}
            onChange={handleInputChange}
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            type="date"
            label="End Date"
            name="endDate"
            value={newEvent.endDate}
            onChange={handleInputChange}
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            label="Event Color"
            name="color"
            type="color"
            value={newEvent.color}
            onChange={handleInputChange}
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            label="Description"
            name="description"
            value={newEvent.description}
            onChange={handleInputChange}
            multiline
            rows={3}
            sx={{ marginBottom: '10px' }}
          />
          <Button variant="contained" onClick={addEvent}>
            Add Event
          </Button>
        </Box>
      </Paper>

      {/* Modal for Event Details */}
      <Modal open={open} onClose={handleModalClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            {selectedEvent ? selectedEvent.title : ''}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Start Date: {selectedEvent ? formatDate(selectedEvent.start) : ''}
          </Typography>
          <Typography sx={{ mt: 1 }}>
            End Date: {selectedEvent ? formatDate(selectedEvent.end) : ''}
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Description: {selectedEvent ? selectedEvent.extendedProps.description : ''}
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default CalendarPage;
