import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import confirmAuth from 'api/confirm'; 
import Button3 from 'components/buttons/Button3';

import OtpInput from 'react-otp-input';

const Confirm = () => {
    const navigate = useNavigate();
    const token = useParams().token;
    const [error, setError] = useState(null);
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);

    const handleAuthConfirm = async () => {
        setLoading(true);
        setError(null); // Réinitialiser l'erreur

        try {
            // Appeler la fonction confirmAuth avec le token et l'OTP
            const response = await confirmAuth(token, otp);

            if (response.success) {
                const token = response.token;
                navigate(`/complete/${token}`)
            } else {
                setError(response.message);
            }
        } catch (err) {
            console.error(err);
            setError('Une erreur est survenue lors de la confirmation. Veuillez réessayer.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box id="signup" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, minHeight: '100vh', position: 'relative' }}>
            {/* Left Section */}
            <Box
                sx={{
                    width: { xs: '100%', md: '50%' },
                    backgroundColor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '2rem',
                }}
            >
                <Box
                    sx={{
                        textAlign: 'left',
                        maxWidth: '600px',
                    }}
                >
                    <img src="/logo.svg" alt="Datalgeria Logo" style={{ width: '150px', marginBottom: '2rem', alignSelf: 'flex-start' }} />

                    {[
                        { title: 'Access to AI Models', description: 'Seamlessly access pre-trained AI models for NLP, computer vision, and more.' },
                        { title: 'Demo Console', description: 'Test and explore our AI models in real-time through an intuitive demo console.' },
                        { title: 'Test API Key', description: 'Easily generate and test API keys for AI integration in your applications.' },
                        { title: 'Custom AI Solutions', description: 'Tailor-made AI solutions crafted to meet your unique business needs.' },
                    ].map((feature, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                marginBottom: '2rem', // Adds space between each feature
                            }}
                        >
                            {/* Circle with check mark */}
                            <Box
                                sx={{
                                    width: '32px',
                                    height: '32px',
                                    borderRadius: '50%',
                                    backgroundColor: '#e5daf8', // Green background for the check circle
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '1rem', // Adds space between the check circle and the text
                                }}
                            >
                                <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    focusable="false"
                                    className="chakra-icon css-s48i3l"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                            </Box>

                            {/* Feature title and description */}
                            <Box>
                                <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: '1rem', marginBottom: '0.8rem' }}>
                                    {feature.title}
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#7d7f83' }}>
                                    {feature.description}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>

            {/* Right Section */}
            <Box
                sx={{
                    width: { xs: '100%', md: '50%' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '2rem',
                    position: 'relative',
                    overflow: 'hidden', // To ensure the background stays within the container
                }}
            >
                {/* Background div */}
                <Box
                    sx={{
                        backgroundImage: 'radial-gradient(at top left, #4f1d9e 30%, transparent 80%), radial-gradient(at bottom, #2ab4d9 0%, transparent 60%), radial-gradient(at bottom left, #fff 0%, transparent 50%)',
                        backgroundBlendMode: 'saturation',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: -1,
                        opacity: 0.2,
                        height: '100%',
                        width: '100%',
                        borderLeftWidth: '1px',
                        borderColor: 'gray.200',
                    }}
                />

                <Typography variant="h5" sx={{ marginBottom: '2rem', textAlign: 'center', fontFamily: 'InterVariable', fontWeight: 'bold', fontSize: '1.5rem' }}>
                    Please confirm your profile
                </Typography>
                <Box sx={{ width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '300px' }}>
                <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        shouldAutoFocus	={true}
        renderInput={(props) => <input {...props} style={{ width: '45px', height: '45px', fontSize: '20px', margin: '0 10px', textAlign: 'center', borderRadius: '5px', border: '1px solid #333', background: 'transparent'

         }} />}
      />
                    <Button3
                        label="Confirm"
                        onClick={handleAuthConfirm}
                        sx={{ marginTop: '2rem' }}
                        loading={loading}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Confirm;
