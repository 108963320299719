import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, Drawer, List, ListItem, ListItemText, Box, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

// Define custom SVG for Moon Icon (dark mode)
const MoonIcon = () => (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    aria-hidden="true"
    focusable="false"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
  </svg>
);

const menuItems = [
  { label: 'Home', path: '/' },
  { label: 'Features', path: '/features' },
  { label: 'Products', path: '/products' },
  { label: 'Club', path: '/club' },
  { label: 'FAQ', path: '/faq' },
  { label: 'Dashboard', path: '/console/dashboard', special: true },
  { icon: <MoonIcon />, path: '/darkmode' },
];

const Navbar2 = () => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [user, setUser] = useState(null);

  // Toggle drawer for mobile view
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('email');
    localStorage.removeItem('username');
    localStorage.removeItem('picture');
    setUser(null);
    window.location.reload();
  };

  // Scroll event handler
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Load user data from localStorage
  useEffect(() => {
    const username = localStorage.getItem('username');
    const picture = localStorage.getItem('picture');

    if (username && picture) {
      setUser({ username, picture });
    }
  }, []);

  // Drawer content for mobile
  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem button key={index} onClick={() => navigate(item.path)}>
            {item.icon && <IconButton>{item.icon}</IconButton>}
            {item.label && <ListItemText primary={item.label} />}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {/* Navbar */}
      <AppBar
        elevation={0}   
        position="fixed" // Make the navbar fixed
        id="navbar"
        className={scrolled ? 'navbar-active' : ''} // Apply navbar-active class based on scroll
        sx={{
          backgroundColor: scrolled ? '#ffffff' : 'transparent', // Change background on scroll
          transition: 'background-color 0.3s ease',
          boxShadow: scrolled ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none',
        }}
      >
        <Toolbar style={{ margin: '0 10%' }}>
          <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
            <img src="/logo.svg" alt="MyLogo" height="30" />
          </Box>

          {/* Menu Items for Larger Screens */}
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 2 }}>
            {menuItems.map((item, index) => (
              <Button
                key={index}
                style={{
                  color: item.special ? 'white' : '#33373d',
                  background: item.special ? 'linear-gradient(90deg, rgb(66, 148, 227), rgb(143, 18, 253))' : 'transparent',
                  borderRadius: item.special ? '20px' : 'none',
                  padding: item.special ? '8px 16px' : '6px 8px',
                  textTransform: 'none',
                  fontFamily: 'InterVariable',
                  fontSize: '0.8125rem',
                }}
                startIcon={item.icon}
                onClick={() => navigate(item.path)}
              >
                {item.label}
              </Button>
            ))}
          </Box>

          {/* User Info or Login Button */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {user ? (
              <>
                <Typography variant="body1" sx={{ 
                    color: '#33373d',
                    fontFamily: 'InterVariable',
                    fontSize: '0.8125rem',
                    fontWeight: 'bold',
                    marginRight: 2 }}>
                  {user.username}
                </Typography>
                <Avatar alt={user.username}
                style={{ cursor: 'pointer' }}
                onClick={() => handleLogout()}
                src={user.picture} />
              </>
            ) : (
              <Button
                onClick={() => navigate('/login')}
                color='#000'
                style={{
                  color: '#000',
                  textTransform: 'none',
                  fontFamily: 'InterVariable',
                  fontSize: '0.8125rem',
                }}
              >
                Login
              </Button>
            )}
          </Box>

          {/* Hamburger Menu for Mobile */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: 'block', sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          {/* Drawer for Mobile Menu */}
          <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            {drawerContent}
          </Drawer>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar2;
