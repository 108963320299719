import React from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { styled } from '@mui/system';
import * as Icons from '@mui/icons-material';
import { Link } from 'react-router-dom';

// Define menu items structure
const menuItems = [
  { header: "Get Started", items: [
    { title: "Overview", to: "/overview", icon: <Icons.InfoOutlined /> },
    { title: "AI Solutions", to: "/ai-solutions", icon: <Icons.PsychologyOutlined /> },
    { title: "Datasets", to: "/datasets", icon: <Icons.StorageOutlined /> },
    { title: "Club", to: "/club", icon: <Icons.GroupOutlined /> },
    { title: "Terms and Policy", to: "/terms-policy", icon: <Icons.PolicyOutlined /> }
  ]},
  { header: "Playground", items: [
    { title: "LLMs", to: "/chat", icon: <Icons.CodeOutlined /> },
    { title: "Sentiment Analysis", to: "/sentiment-analysis", icon: <Icons.MoodOutlined /> },
    { title: "Translation Model", to: "/translation-model", icon: <Icons.TranslateOutlined /> },
    { title: "Regional Classification", to: "/regional-classification", icon: <Icons.MapOutlined /> },
    { title: "TTS", to: "/tts", icon: <Icons.RecordVoiceOverOutlined /> },
    { title: "Image Generation", to: "/image-generation", icon: <Icons.ImageOutlined /> }
  ]},
  { header: "Data Providing", items: [
    { title: "Exchange Rate", to: "/exchange-rate", icon: <Icons.AttachMoneyOutlined /> },
    { title: "Name API", to: "/name-api", icon: <Icons.PersonSearchOutlined /> }
  ]},
  { header: "Resources", items: [
    { title: "API Keys", to: "/api-keys", icon: <Icons.VpnKeyOutlined /> },
    { title: "Documentation", to: "/documentation", icon: <Icons.DescriptionOutlined /> },
    { title: "Activity", to: "/activity", icon: <Icons.TimelineOutlined /> },
    { title: "Billing", to: "/billing", icon: <Icons.ReceiptOutlined /> },
    { title: "Integration", to: "/integration", icon: <Icons.ExtensionOutlined /> }
  ]}
];

// Styled Sidebar for Dark Mode
const StyledSidebar = styled(Sidebar)({
  height: '100vh', 
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: '#1e1e1e',  // Dark mode background color
  overflowY: 'auto', // Make content scrollable
  width: '250px',
  borderRight: '0px solid #000',  // Dark mode border
  padding: '0px 0',
});

// Menu Header Style for Dark Mode
const MenuHeader = styled('div')({
  padding: '10px 20px',
  fontSize: '12px',  // Smaller text
  fontWeight: 'bold',
  color: '#bbb',  // Light gray text for dark mode
  textTransform: 'uppercase',
  letterSpacing: '1px',
  borderBottom: '1px solid #333',  // Dark mode border
  marginBottom: '5px',
});

// Styled MenuItem for Dark Mode
const StyledMenuItem = styled(MenuItem)({
  fontSize: '0.8em',  // Smaller text
  fontFamily: 'InterVariable',
  color: '#ddd',  // Light text color for dark mode
  '&:hover': {
    backgroundColor: '#000',  // Hover effect for dark mode
  },
  '& svg': {
    color: '#bbb'  // Light gray icons for dark mode
  },
});
const SidebarComponent = () => {
  return (
    <StyledSidebar
      backgroundColor="#1e1e1e"  // Dark mode background color
      style={{
        borderRight: '0px solid #000',  // Dark mode border
      }}
    >
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <img src="/logo.svg" alt="Logo" style={{ width: '150px' }} />
      </div>
      <Menu>
        {menuItems.map((section, index) => (
          <div key={index}>
            <MenuHeader>{section.header}</MenuHeader>
            {section.items.map((item, idx) => (
              <StyledMenuItem
                key={idx}
                icon={React.cloneElement(item.icon, { 
                  fontSize: '1.5em' })}  // Adjust icon size
                component={<Link to={`/console${item.to}`} />}
              >
                <span style={{ 
fontFamily: 'InterVariable',

                 }}>
                {item.title}
                </span>
              </StyledMenuItem>
            ))}
          </div>
        ))}
      </Menu>
    </StyledSidebar>
  );
};

export default SidebarComponent;
