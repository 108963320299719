import React, { useState } from 'react';
import { Container, Box, TextField, Typography, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import axios from 'axios';

const TranslationPage = () => {
  const [sourceText, setSourceText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleTranslate = async (text) => {
    if (!text.trim()) {
      setTranslatedText('');
      return;
    }

    setLoading(true);

    try {
      // Example API call to a translation service
      const response = await axios.post('https://example.com/api/translate', {
        text: text,
        from: 'en', // Change to your source language code
        to: 'fr',   // Change to your target language code
      });

      setTranslatedText(response.data.translatedText || ''); // Assuming the response has the translated text
    } catch (error) {
      console.error("Error during translation:", error);
      setTranslatedText('Error translating text.');
    } finally {
      setLoading(false);
    }
  };

  // Handle input change for real-time translation
  const handleSourceTextChange = (event) => {
    const text = event.target.value;
    setSourceText(text);
    handleTranslate(text); // Translate whenever source text changes
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'center',
        backgroundColor: '#f5f5f5', // Light background color
        color: '#000', // Black text
      }}
    >
      <Typography variant="h4" gutterBottom>
        Real-Time Text Translator
      </Typography>

      <Box sx={{ width: '100%', maxWidth: '800px' }}>
        <Paper
          elevation={3}
          sx={{
            padding: '20px',
            marginBottom: '20px',
            backgroundColor: '#ffffff', // White background for Paper
            borderRadius: '8px',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <TextField
              label="Enter text to translate"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={sourceText}
              onChange={handleSourceTextChange}
              sx={{
                margin: '0 10px',
                '& label.Mui-focused': { color: 'white' },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'white' },
                    '&:hover fieldset': { borderColor: 'white' },
                    '&.Mui-focused fieldset': { borderColor: 'white' },
                },
            }}
            />
          </motion.div>
        </Paper>

        <Paper
          elevation={3}
          sx={{
            padding: '20px',
            backgroundColor: '#ffffff', // White background for Paper
            borderRadius: '8px',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }} // Delay for animation
          >
            <TextField
              label="Translated text"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={translatedText}
              sx={{
                margin: '0 10px',
                '& label.Mui-focused': { color: 'white' },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: 'white' },
                    '&:hover fieldset': { borderColor: 'white' },
                    '&.Mui-focused fieldset': { borderColor: 'white' },
                },
            }}
            />
          </motion.div>
        </Paper>
      </Box>
    </Container>
  );
};

export default TranslationPage;
