import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Box,
  Chip,
  Grid,
} from '@mui/material';

const ValidationInfoDialog = ({ open, onClose, validation }) => {
  const questions = [
    "Why do you want to join the club of Datalgeria ?",
    "What are your expectations from the club of Datalgeria ?",
    "How did you hear about the club of Datalgeria ?",
  ];

  const { q1, q2, q3, skills, techs } = validation || {};
  const skillsArray = skills ? JSON.parse(skills) : [];
  const techsArray = techs ? JSON.parse(techs) : [];

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const currentDate = new Date().toLocaleDateString();

    const printContent = `
      <html>
        <head>
          <title>Validation Details</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 20px; }
            h2 { text-align: center; }
            .section { margin-bottom: 20px; }
            .section-title { font-weight: bold; margin-bottom: 5px; }
            .logo { display: block; margin: 0 auto 20px; width: 150px; }
          </style>
        </head>
        <body>
          <img src="/logo.svg" class="logo" alt="Logo" />
          <h2>Validation Details</h2>
          <p>Date: ${currentDate}</p>
          <div class="section">
            <div class="section-title">Personal Information</div>
            <p><strong>Name:</strong> ${validation.name}</p>
            <p><strong>First Name:</strong> ${validation.fname}</p>
            <p><strong>Email:</strong> ${validation.email}</p>
            <p><strong>Phone:</strong> ${validation.phone}</p>
          </div>
          <div class="section">
            <div class="section-title">Work & Status</div>
            <p><strong>Current Work:</strong> ${validation.actual_work || 'N/A'}</p>
            <p><strong>Is Working:</strong> ${validation.is_working ? 'Yes' : 'No'}</p>
            <p><strong>Has Company:</strong> ${validation.has_company ? 'Yes' : 'No'}</p>
          </div>
          <div class="section">
            <div class="section-title">Location & Education</div>
            <p><strong>Wilaya:</strong> ${validation.wilaya_id}</p>
            <p><strong>Commune:</strong> ${validation.commune_id}</p>
            <p><strong>University:</strong> ${validation.university}</p>
          </div>
          <div class="section">
            <div class="section-title">Skills</div>
            ${skillsArray.map(skill => `<span style="margin-right: 5px;">${skill}</span>`).join('')}
          </div>
          <div class="section">
            <div class="section-title">Technologies</div>
            ${techsArray.map(tech => `<span style="margin-right: 5px;">${tech}</span>`).join('')}
          </div>
          <div class="section">
            <div class="section-title">Club Questions & Answers</div>
            <p><strong>${questions[0]}</strong></p>
            <p>${q1 || 'No response'}</p>
            <p><strong>${questions[1]}</strong></p>
            <p>${q2 || 'No response'}</p>
            <p><strong>${questions[2]}</strong></p>
            <p>${q3 || 'No response'}</p>
          </div>
          ${validation.website ? `
            <div class="section">
              <div class="section-title">Website</div>
              <p><strong>Website:</strong> <a href="${validation.website}" target="_blank">${validation.website}</a></p>
            </div>` : ''}
        </body>
      </html>
    `;

    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Validation Details</DialogTitle>
      <DialogContent>
        {validation ? (
          <Box>
            {/* Personal Information */}
            <Typography variant="h6" gutterBottom>
              Personal Information
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1"><strong>Name:</strong> {validation.name}</Typography>
              <Typography variant="body1"><strong>First Name:</strong> {validation.fname}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1"><strong>Email:</strong> {validation.email}</Typography>
              <Typography variant="body1"><strong>Phone:</strong> {validation.phone}</Typography>
            </Box>

            {/* Work & Status */}
            <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
              Work & Status
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1"><strong>Current Work:</strong></Typography>
              <Typography variant="body1">{validation.actual_work || 'N/A'}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1"><strong>Is Working:</strong> {validation.is_working ? 'Yes' : 'No'}</Typography>
              <Typography variant="body1"><strong>Has Company:</strong> {validation.has_company ? 'Yes' : 'No'}</Typography>
            </Box>

            {/* Location & Education */}
            <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
              Location & Education
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1"><strong>Wilaya:</strong> {validation.wilaya_id}</Typography>
              <Typography variant="body1"><strong>Commune:</strong> {validation.commune_id}</Typography>
            </Box>
            <Typography variant="body1"><strong>University:</strong> {validation.university}</Typography>

            {/* Skills & Technologies */}
            <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
              Skills & Technologies
            </Typography>
            <Box>
              <Typography variant="body1"><strong>Skills:</strong></Typography>
              <Grid container spacing={1}>
                {skillsArray.length > 0 ? (
                  skillsArray.map((skill, index) => (
                    <Grid item key={index}>
                      <Chip label={skill} color="primary" />
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body2">No skills listed</Typography>
                )}
              </Grid>
            </Box>
            <Box style={{ marginTop: '16px' }}>
              <Typography variant="body1"><strong>Technologies:</strong></Typography>
              <Grid container spacing={1}>
                {techsArray.length > 0 ? (
                  techsArray.map((tech, index) => (
                    <Grid item key={index}>
                      <Chip label={tech} color="secondary" />
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body2">No technologies listed</Typography>
                )}
              </Grid>
            </Box>

            {/* Questions and Answers */}
            <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
              Club Questions & Answers
            </Typography>
            <Box>
              <Typography variant="body1"><strong>{questions[0]}</strong></Typography>
              <Typography variant="body2">{q1 || 'No response'}</Typography>
            </Box>
            <Box style={{ marginTop: '8px' }}>
              <Typography variant="body1"><strong>{questions[1]}</strong></Typography>
              <Typography variant="body2">{q2 || 'No response'}</Typography>
            </Box>
            <Box style={{ marginTop: '8px' }}>
              <Typography variant="body1"><strong>{questions[2]}</strong></Typography>
              <Typography variant="body2">{q3 || 'No response'}</Typography>
            </Box>

            {/* Website */}
            {validation.website && (
              <>
                <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
                  Website
                </Typography>
                <Typography variant="body1"><strong>Website:</strong> <a href={validation.website} target="_blank" rel="noopener noreferrer">{validation.website}</a></Typography>
              </>
            )}
          </Box>
        ) : (
          <Typography>No details available.</Typography>
        
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
        <Button onClick={handlePrint} color="primary">Print</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationInfoDialog;
