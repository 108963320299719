import React from 'react';
import { Container } from '@mui/material';
import Grid1 from '../../components/grids/Grid1';
import Header1 from '../../components/headers/Header1';
import Card from '../../components/cards/Card'; // Import the Card <component></component>
import Card2 from '../../components/cards/Card2'; // Import the Card2 <component></component>
import Translation from '../../components/products/features/Translation'; // Import the Translation component
import Tags from '../../components/products/features/Tags'; // Import the Tags component
import Liveness from '../../components/products/features/Liveness'; // Import the Liveness component

const Security = () => {
  return (
    <Container sx={{ mx: 'auto', padding: '2rem', maxWidth: '100%' }}>
      <Header1 text="Security and verifications" />
      <br />
      <br />
      <br />

      <Grid1>
        {/* First item spanning 2 columns */}
        <Card
          title="Liveness Face Detection"
          description="Ensure the user is physically present and not an imposter by analyzing real-time facial movements, detecting subtle signs of life, and verifying authenticity in a secure and reliable manner."
          content={<Translation />} // Use the Translation component as the content
          sx={{
            gridColumn: { xs: 'span 1', md: 'span 2' }, // Spans 2 columns on medium screens and above
          }}
        />

        <Card2
          content={<Liveness />} // Use the Liveness component as the content
          sx={{ gridColumn: { xs: 'span 1', md: 'span 1' } }} // Regular span
        />

        <Card
            title="Document Verification"
            description="Automatically analyze and validate documents such as ID cards, passports, and other official papers, ensuring their legitimacy and compliance with regulations."
            content={<Tags tags={['ID Card', 'Passport', 'Driver\'s License', 'Residence Permit']} />} // Use the Tags component as the content

            sx={{
                gridColumn: { xs: 'span 1', md: 'span 2' }, // Spans 2 columns on medium screens and above
              }}
        />

        {/* Last item spanning 2 columns */}
        <Card
          title="ID Verification"
          description="Securely verify the authenticity of identity documents, ensuring that the submitted IDs are genuine and belong to the correct individuals."
   
          sx={{ gridColumn: { xs: 'span 1', md: 'span 1' } }} // Regular span

        />
      </Grid1>
    </Container>
  );
};

export default Security;
