import React from 'react';
import { Box } from '@mui/material';

const Tags = ({ tags }) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexWrap: 'wrap', // Allows wrapping of tags if there are too many
        maxWidth: '100%',
      }}
    >
      {tags.map((tag, index) => (
        <Box
          key={index}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            fontWeight: 'medium',
            lineHeight: 1.2,
            outline: '2px solid transparent',
            outlineOffset: '2px',
            padding: '0.5rem 1rem', // Add some padding to each tag
            margin: '0.5rem', // Add some margin between tags
            backgroundColor: '#f0f0f0', // Background color for visibility
            borderRadius: '4px', // Rounded corners
            cursor: 'pointer', // Change cursor to pointer on hover
            transition: 'background-color 0.2s', // Transition for hover effect
            '&:hover': {
              backgroundColor: '#d3d3d3', // Change background on hover
            },
          }}
        >
          {tag}
        </Box>
      ))}
    </Box>
  );
};

export default Tags;
