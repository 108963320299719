import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  TextField,
} from "@mui/material";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const PAGE_SIZE = 10;
  const authToken = localStorage.getItem('authToken');

  const fetchProjects = async () => {
    const response = await axios.get(`https://api.datalgeria.com/president/projects`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        page,
        search
      }
    });
    
    setProjects(response.data.projects);
    setTotal(response.data.total);
  };

  useEffect(() => {
    fetchProjects();
  }, [page, search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to page 1 when a new search is initiated
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box>
      <Typography variant="h4">Projects</Typography>

      {/* Search bar */}
      <TextField
        label="Search Projects"
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
        style={{ marginBottom: '20px' }}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nom du Projet</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project) => (
              <TableRow key={project.id}>
                <TableCell>{project.id}</TableCell>
                <TableCell>{project.name}</TableCell>
                <TableCell>
                  <Button component={Link} to={`/club/president/project/${project.id}/sections`}>
                    Sections
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Pagination
        count={Math.ceil(total / PAGE_SIZE)}
        page={page}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
    </Box>
  );
};

export default Projects;
