import axios from 'axios';

const confirmAuth = async (id, otp) => {
    try {
        const response = await axios.post('https://api.datalgeria.com/auth/confirm-otp', { id, otp });
        return response.data; // Retourne les données de la réponse
    } catch (error) {
        throw error; // Propage l'erreur pour être gérée dans le composant
    }
};

export default confirmAuth;
