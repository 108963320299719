import React, { useEffect, useRef, useCallback, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Select,
  MenuItem,
  Avatar,
  ListItemText,
  ListItemAvatar,
  Chip
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./editorTools";

const SectionSteps = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const { sectionId } = useParams(); // Get project ID from URL
  const [sections, setSections] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const PAGE_SIZE = 10;
  const authToken = localStorage.getItem("authToken");
  const editorCore = useRef(null);
  const ReactEditorJS = createReactEditorJS();
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);

  // Users state
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleInitialize = useCallback((instance) => {
    instance._editorJS.isReady
      .then(() => {
        editorCore.current = instance;
      })
      .catch((err) => console.log("An error occurred", err));
  }, []);

  const handleSave = useCallback(async () => {
    const savedData = await editorCore.current.save();
    setDescription(savedData);

    try {
      // API call to save section and selected users
      await axios.post(
        `https://api.datalgeria.com/president/project/${sectionId}/sections`,
        { name, description: JSON.stringify(savedData), users: selectedUsers },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // Refresh sections list after adding new section
      fetchSections();
      setOpenDescriptionModal(false); // Close the modal
    } catch (error) {
      console.error("Error saving section:", error);
    }
  }, [name, description, sectionId, authToken, selectedUsers]);

  const fetchSections = async () => {
    const response = await axios.get(
      `https://api.datalgeria.com/president/project/${sectionId}/sections`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          page,
        },
      }
    );

    setSections(response.data.sections);
    setTotal(response.data.total);
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `https://api.datalgeria.com/president/getProjectUsers/${sectionId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setUsers(response.data.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
useEffect(() => {
    fetchSections();
}, [page]);

useEffect(() => {
    const timer = setTimeout(() => {
        fetchUsers(); // Fetch users when page loads
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
}, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    setSelectedUsers(event.target.checked ? users.map(user => user.id) : []);
  };

  const handleUserSelect = (event) => {
    const value = event.target.value;
    setSelectedUsers(value);
  };

  const renderSelectedUsers = () => {
    return selectedUsers.map((id) => {
      const user = users.find(user => user.id === id);
      return (
        user && (
          <Chip
            key={user.id}
            avatar={<Avatar src={user.picture} alt={user.name} />}
            label={user.name}
            variant="outlined"
            sx={{ margin: 0.5 }}
          />
        )
      );
    });
  };

  return (
    <Box>
      <Typography variant="h4">Sections du Projet</Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => setOpenDescriptionModal(true)}
      >
        Ajouter une Section
      </Button>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nom de la Section</TableCell>
              <TableCell>Actions</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {sections.map((section) => (
              <TableRow key={section.id}>
                <TableCell>{section.id}</TableCell>
                <TableCell>{section.name}</TableCell>
                <TableCell>
                  <Button component={Link} to={`/club/president/project/${section.id}/sections`}>
                    Steps
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Pagination
        count={Math.ceil(total / PAGE_SIZE)}
        page={page}
        onChange={handlePageChange}
        style={{ marginTop: "20px" }}
      />

      {/* Modal for adding a new section */}
      <Dialog
        open={openDescriptionModal}
        onClose={() => setOpenDescriptionModal(false)}
        fullWidth
      >
        <DialogTitle>Ajouter une Section</DialogTitle>

        <DialogContent>
          <TextField
            label="Nom de la section"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ marginTop: 2, marginBottom: 2 }}
          />

          {/* Select Users */}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  color="primary"
                />
              }
              label="Select All"
            />
            <Select
              multiple
              value={selectedUsers}
              onChange={handleUserSelect}
              renderValue={renderSelectedUsers} // Display selected user avatars and names
              fullWidth
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  <ListItemAvatar>
                    <Avatar src={user.picture} alt={user.name} /> {/* User's picture */}
                  </ListItemAvatar>
                  <ListItemText primary={user.name} /> {/* User's name */}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>

          <ReactEditorJS
            defaultValue={description}
            onInitialize={handleInitialize}
            ref={editorCore}
            tools={EDITOR_JS_TOOLS}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenDescriptionModal(false)}>Annuler</Button>
          <Button onClick={handleSave}>Enregistrer</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SectionSteps;
