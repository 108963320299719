import React from 'react';
import { Button } from '@mui/material';

const Button2 = ({ label, icon, onClick, sx }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        background: 'transparent',
        color: '#33373d', // Text color
        border: '1px solid #e7e7e8', // Border color
        padding: '10px 20px',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px', // Space between label and icon
        transition: '0.3s ease',
        '& .icon': {
            transform: 'translateY(2px)', // Initial icon position
        },
            '&:hover': {
          background: 'rgba(0, 0, 0, 0.05)', // Optional hover background
          '& .icon': {
            transform: 'translateX(5px) translateY(2px)', // Move icon on hover
          },
        },
        ...sx, // Allow additional styles via sx prop
      }}
    >
      <span>{label}</span>
      <span className="icon" style={{ transition: '0.3s ease' }}>
        {icon}
      </span>
    </Button>
  );
};

export default Button2;
